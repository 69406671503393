import React from 'react'
import { Link } from 'react-router-dom'

export default function Footer() {
    return (
        <div className='footer'>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-6 col-md-4 mt-5'>
                       <img src={'images/G.png'} height="35" width="150" alt='GainWorkers' />
                       <div className='row mt-2'>
                           <div className='col-md-3'>
                               <Link><i class="fab fa-facebook-f"></i></Link>
                           </div>
                           <div className='col-md-3'>
                               <Link><i class="fab fa-twitter"></i></Link>
                           </div>
                           <div className='col-md-3'>
                               <Link><i class="fab fa-instagram"></i></Link>
                           </div>
                           <div className='col-md-3'>
                               <Link><i class="far fa-envelope"></i></Link>
                           </div>
                           
                           
                       </div>
                    </div>
                    <div className='col-6 offset-md-3 col-md-5 mt-5 text-right'>
                        <div className='row'>
                            <div className='col-12 col-md-4'>
                                <Link>Contact</Link> 
                            </div>
                            <div className='col-12 col-md-4'>
                                <Link>Terms Of Service</Link> 
                            </div>
                            <div className='col-12 col-md-4'>
                                <Link>Privacy Policy</Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div className='row justify-content-center'>
                <div className='auto'>
                    <p>©gainworkers.com Copyright © 2022. All Rights Reserved.</p>
                </div>
            </div>
        </div>
    )
}
