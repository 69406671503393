import axios from 'axios';
import React, {useState, useRef, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import {Accordion, Card, Button, Spinner} from "react-bootstrap";
import { updateAvatar, updateProfile, updateWithdraw } from '../../utils/postData';
import Advert from '../Homepage/Pages/Advert';


export default function Profile() {
    const[open, setOpen] = useState(false)
    const[isOpen, setIsOpen] = useState(false)
    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [account, setAccount] = useState('');
    const [ref, setRef] = useState('');
    const [referral, setRefferal] = useState(0);
    const [balance, setBalance] = useState(0);
    const [taskBalance, setTaskBalance] = useState(0);
    const [totalWithdrawal, setTotalWithdrawal] = useState(0);
    const [updatedAt, setUpdatedAt] = useState('');
    const [createdAt, setCreatedAt] = useState('');
    const [picture, setPicture] = useState(null);
    const [successfulTask, setSuccessfulTask] = useState(0);
    const [unsuccessful, setUnsuccessful] = useState(0);
    const [message, setMessage] = useState('');
    const [postedTask, setPostedTask] = useState(0);
    const [pendingTaskReview, setPendingTaskReview] = useState(0);
    const [rejectedTaskReview, setRejectedTaskReview] = useState(0);
    const [pending, setPending] = useState(0)
    const History = useHistory()
    const [state, setState]= useState({
        telephone: '',
        country:'',
        state: '',
        fetched: false,
        avatar: null,
        birth_date:''
    });
    const[loading, setLoading] = useState(false)
    const [withdraw, setWithdraw] = useState({
        bank: "First",
        fetched: false,
        account_name:'',
        account_number: '',
    })
    const history = useHistory()
    useEffect(() => {
        if(localStorage.getItem('profile')){
            const base_url = 'https://immense-bayou-01640.herokuapp.com';
            axios.get(`${base_url}/users/profile`).then(res=>{
                console.log(res.data)
                setUsername(res.data.username)
                setName(res.data.name)
                setAccount(res.data.account_type)
                setRef(res.data.referralLink);
                setRefferal(res.data.ref);
                setBalance(res.data.balance);
                setSuccessfulTask(res.data.successTask)
                setUnsuccessful(res.data.unsuccessful)
                setTaskBalance(res.data.taskBalance)
                setUpdatedAt(res.data.updatedAt);
                setCreatedAt(res.data.createdAt);
                setPicture(res.data.avatar)
                setState({telephone: res.data.telephone, 
                    country: res.data.country, 
                    birth_date: res.data.birth_date,
                    fetched: res.data.telephone ? true : false,
                    state: res.data.state})
                setWithdraw({
                    bank: res.data.withdrawal.bank,
                    fetched: res.data.withdrawal.account_number? true : false,
                    account_name: res.data.withdrawal.account_name,
                    account_number: res.data.withdrawal.account_number
                })
                setPostedTask(res.data.postedTask)
                setPending(res.data.pending)
                setPendingTaskReview(res.data.pendingTaskReview)
                setRejectedTaskReview(res.data.rejectedTaskReview)
            })

        }
        else{
            History.push('/login')
        }
    }, []);
    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    const handleChangeWithdraw = (e)=>{
        const value = e.target.type==='checkbox' ? e.target.checked: e.target.value
        setWithdraw({
            ...withdraw,
            [e.target.name]:  value,
        })
    }
    const onFileChange = (e)=>{
        document.querySelector('#disp').style.display = 'inline'
        setPicture(e.target.files[0])
    }
    const handleUpload = (e)=>{
        e.preventDefault()
        setLoading(!loading)
        let formData = new FormData;
        formData.append('avatar', picture);
        updateAvatar(formData).then(res=>{
            setMessage(res.data)
            setLoading(!loading)
        }).catch(err=>{
            console.log(err)
            setMessage('Something went wrong')
        })
    }
    const logout = (e)=>{
        // dispatch({type: 'LOGOUT'})
        history.push("/home")
        localStorage.clear("profile")
        
        // window.location.reload();   
    }
    const handleWithdrawal = e =>{
        e.preventDefault()
        let formData = withdraw
        updateWithdraw(formData).then(res=>{
            setMessage(res.data)
        }).catch(err=>{
            if(err.response){
                setMessage(err.response.data)
            }
        })
    }
    const handleSubmit= async e=>{
        e.preventDefault()
        // console.log(state.avatar)
        let formData = {'telephone': state.telephone, 'country':state.country,
                    'state':state.state, 'birth_date':state.birth_date}
        try {
            const result =await updateProfile(formData);
            setMessage(result.data.message)
            
        } catch (error) {
            setMessage('something went wrong')
        }

 }
    return (
        <div className='container after history mb-3'>
            <Advert/>
            <h4 className='text-center mt-4 mb-5' style={{fontSize: "20px"}}>Welcome to your Dashboard <b>{name}</b></h4>
            {/* Small screen size */}
            <div className='row small line'>
                <div className='col-12'>
                    <div className='card dashboard'>
                        <div className='row'>
                            <div className='col-12 text-center'>
                            {picture ? <img src={picture} 
                                alt='profile-picture' 
                                className='rounded-circle mt-2'
                                height={60} width={60}/>: <img src={"images/avatar.png"} 
                                alt='profile-picture' className='rounded-circle mt-2'
                                height={60} width={60}/>}
                                <p className='mb-0'>{account}</p>
                                <p className='mb-0'>{username}</p>
                                <Link to='/account/upgrade'>Upgrade Membership</Link>
                            </div>
                            
                        </div>
                        <hr/>
                        <div className='row'>
                            <div className='col-7'>
                                <ul className='list-unstyled ml-2'>
                                    <li>Balance: <b><span>&#8358;</span>{taskBalance}</b></li>
                                    <li>Total Deposit: <b><span>&#8358;</span>{balance}</b></li>
                                    <li>Total Withdrawal: <b><span>&#8358;</span>{totalWithdrawal}</b></li>
                                    <li><Link to='/transaction/history'>Transaction History</Link> </li>
                                    <li><Link to='/rave'>Deposit</Link> <span className='ml-3'> <Link to='/withdraw'>Withdraw</Link></span> </li>
                                    <li>Membership Since:  <small><b>{createdAt.slice(0,10) + " at " + createdAt.slice(11,19)} </b></small></li>
                                    <li>Last Login: <small><b>{updatedAt.slice(0,10) + " at " + updatedAt.slice(11,19)} </b></small></li>
                                </ul>
                            </div>
                            <div className='col-5'>
                                <ul className='list-unstyled'>
                                    <li>My Referrals: <b>{ referral.length}</b></li>
                                    <li>Rated: <b><i className="fa fa-thumbs-up" aria-hidden="true"> </i> 
                                        {successfulTask.length} | <i className="fa fa-thumbs-down" aria-hidden="true"> </i> {unsuccessful.length} 
                                    </b></li>
                                    <li>
                                        Posted Tasks: <b>{ postedTask.length}</b> 
                                    </li>
                                    <li>Pending Tasks Review: <b>{pendingTaskReview.length}</b></li>
                                    <li>Rejected Tasks Review: <b>{rejectedTaskReview.length}</b></li>
                                    <li>Successful Tasks: <b>{successfulTask.length }</b></li>
                                    <li>Pending Tasks: <b>{pending.length}</b> </li>
                                    <li>Unsuccessful Task: <b>{unsuccessful.length }</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='row big line'>
                <div className='col-12'>
                    <div className='card dashboard'>
                        <div className='row'>
                            <div className='col-md-3 first text-center'>
                                {picture ? <img src={picture} 
                                alt='profile-picture' 
                                className='rounded-circle mt-2'
                                height={40} width={50}/>: <img src={"images/avatar.png"} 
                                alt='profile-picture' className='rounded-circle mt-2'
                                height={40} width={50}/>}
                                <p className='mb-0'>{account}</p>
                                <p className='mb-0'>{username}</p>
                                <Link to='/account/upgrade'>Upgrade Membership</Link>
                            </div>
                            
                            <div className='col-md-3 first text-center'>
                                <ul className='list-unstyled'>
                                    <li>Balance: <b> <span>&#8358;</span> {taskBalance}</b></li>
                                    <li>Total Deposit: <b><span>&#8358;</span>{balance}</b></li>
                                    <li>Total Withdrawal: <b><span>&#8358;</span>{totalWithdrawal}</b></li>
                                    <li><Link to='/transaction/history'>Transaction History</Link> </li>
                                    <li><Link to='/rave'>Deposit</Link> <span className='ml-3'> <Link to='/withdraw'>Withdraw</Link></span> </li>
                                </ul>
                            </div>
                            <div className='col-md-3 first text-center'>
                                <ul className='list-unstyled'>
                                    <li>My Referrals: <b>{ referral.length}</b></li>
                                    <li>Rated: <b><i className="fa fa-thumbs-up" aria-hidden="true"> </i> 
                                        { successfulTask.length} | <i className="fa fa-thumbs-down" aria-hidden="true"> </i> {unsuccessful.length} 
                                    </b></li>
                                    <li>Membership Since:  <small><b>{createdAt.slice(0,10) + " at " + createdAt.slice(11,19)} </b></small></li>
                                    <li>Last Login: <small><b>{updatedAt.slice(0,10) + " at " + updatedAt.slice(11,19)} </b></small></li>
                                    <li>
                                        Posted Tasks: <b>{ postedTask.length}</b> 
                                    </li>
                                </ul>
                            </div>
                            <div className='col-md-3 text-center '>
                                <ul className='list-unstyled'>
                                    <li>Pending Tasks Review: <b>{pendingTaskReview.length}</b></li>
                                    <li>Rejected Tasks Review: <b>{rejectedTaskReview.length}</b></li>
                                    <li>Successful Tasks: <b>{successfulTask.length }</b></li>
                                    <li>Pending Tasks: <b>{pending.length}</b> </li>
                                    <li>Unsuccessful Task: <b>{unsuccessful.length }</b></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center mt-4 mb-4'>
                <b>My Profile Details</b>
            </div>
            <div className='row mb-3 mt-3'>
                <div className='col-md-6 col-12 mb-2'>
                    <b className='ml-2'>Upload profile image:</b>
                    <span>
                        <form>
                            <input type='file' 
                            accept="image/*" className='ml-2' 
                            onChange={onFileChange}/> <span><button className='btn btn-sm btn-login' id='disp' style={{display: "none"}} onClick={handleUpload}>{loading ? <Spinner className='border'/>: <>upload</>}</button> </span>
                        </form>
                    </span>
                </div>
                <div className='col-md-6 col-12 right'>
                    Referral link:
                    <p><Link to={`ref/${username}`}>{ref}</Link></p>
                </div>
            </div>
            
            {/* Small screen size */}
            
            <form onSubmit={(values) => handleSubmit(values)} className="needs-validation sign"  noValidate>
                        <div className='container'>
                            <p className='text-center'>{message}</p>
                            <div className="form-group row mt-4">
                                <div className="col-md-6 col-12 ">
                                    <label>Phone Number</label>
                                    <input type="text" id="firstname" name="telephone"
                                        placeholder="telephone"   onChange={handleChange}
                                        value={state.telephone} readOnly={state.fetched ? true: false}
                                        autoComplete={false}
                                        className="form-control" required
                                        />
                                </div>
                                <div className="col-md-6 col-12 margin">
                                    <label>Date of Birth</label> 
                                    <input type="date" id="date" name="birth_date"
                                        onChange={handleChange}  value={state.birth_date}
                                        readOnly={state.fetched ? true: false}
                                        autoComplete={false}
                                        className="form-control" required
                                            />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-md-6 col-12">
                                <label>Country</label> 
                                <input type="text"  id="email" name="country"
                                                    placeholder="Country" onChange={handleChange}
                                                    value={state.country} readOnly={state.fetched ? true: false}
                                                    autoComplete={false}
                                                    className="form-control" required/>
                                </div>
                                <div className="col-md-6 col-12">
                                        < label>State</label>
                                        <input type="text"  name="state" 
                                                value={state.state} readOnly={state.fetched ?  true: false}
                                                placeholder="state" onChange={handleChange}
                                                className="form-control" />
                                </div>
                                </div>     
                                <div className="form-group text-right pb-3">
                                    <button type='submit' 
                                    disabled={state.fetched? true: false}
                                    className='btn btn-md btn-login'>Update Profile</button>
                                </div>
                            </div>
                </form>

                {/* Small screen */}
                <div className='row small mt-3'>
                <div className='col-12'>
                  <Accordion className="accord">
                        <Accordion.Toggle as={Button} variant='link' 
                        className='w-100' eventKey='1'
                        onClick={()=> {setIsOpen(!isOpen) 
                        setOpen(false)}}> 
                        <div className='row'>
                            <div className='col-7 text-left'>
                                <h6>Update Withdrawal Details</h6>
                            </div>
                            <div className='col-5 text-right'>
                                <h6>{isOpen? <i className='fas fa-chevron-down'></i> : <i className='fas fa-chevron-right'></i>}</h6>
                            </div>
                        </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey='1'>
                        <Card.Body>
                        <form onSubmit={(values) => handleWithdrawal(values)} className="needs-validation sign"  noValidate>
                            <div className='container'>
                                <p className='text-center mt-2'>{message}</p>
                                <div className="form-group row mt-4">
                                    <div className="col-md-6 col-12 ">
                                        <label>Account Name</label>
                                        <input type="text" id="firstname" name="account_name"
                                            placeholder="Tife Samuel" value={withdraw.account_name}   onChange={handleChangeWithdraw}
                                            className="form-control" required readOnly={withdraw.fetched? true : false}
                                            autoComplete={false}
                                            />
                                    </div>
                                    <div className="col-md-6 col-12 mt-2 margin">
                                        <label>Account Number</label> 
                                        <input type="text" id="acc" name="account_number"
                                            onChange={handleChangeWithdraw}
                                            value={withdraw.account_number}
                                            readOnly={withdraw.fetched?  true : false}
                                            autoComplete={false}
                                            className="form-control" required
                                                />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-md-6 col-12">
                                    <label>Bank</label> 
                                    <select name="bank" className="form-control" 
                                         onChange={handleChangeWithdraw} value={withdraw.bank}
                                         readOnly={withdraw.fetched? true : false}
                                         autoComplete={false}>
                                            <option>First</option>
                                            <option >Polaris</option>
                                            <option>GTB</option>
                                            <option>Access</option>
                                            <option>UBA</option>
                                            <option>Zenith</option>
                                    </select> 
                                    </div>
                                    </div>     
                                    <div className="form-group text-right pb-3">
                                        <button type='submit' 
                                        disabled={withdraw.fetched ? true : false}
                                        className='btn btn-md btn-login'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                        </form>
                        </Card.Body>
                        {/* <Card.Footer>
                            <p className='card-text' dangerouslySetInnerHTML={{__html:w.text_prove}}></p>
                        </Card.Footer> */}
                        </Accordion.Collapse>
                    </Accordion>
                    </div>
                </div>

                <div className='row big'>
                <div className='col-12'>
                  <Accordion className="accord">
                    <Accordion.Toggle as={Button} 
                    variant='link' 
                    className='w-100' 
                    eventKey='0'
                    onClick={()=> setOpen(!open)}> 
                        <div className='row'>
                            <div className='col-5 text-left'>
                                <h6>Update Withdrawal Details</h6>
                            </div>
                            <div className='col-7 text-right'>
                                <h6>{open? <i className='fas fa-chevron-down'></i> : <i className='fas fa-chevron-right'></i>}</h6>
                            </div>
                        </div>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey='0'>
                    <form onSubmit={(values) => handleWithdrawal(values)} className="needs-validation sign"  noValidate>
                    <div className='container'>
                                <p className='text-center mt-2'>{message}</p>
                                <div className="form-group row mt-4">
                                    <div className="col-12 ">
                                        <label>Account Name</label>
                                        <input type="text" id="firstname" name="account_name"
                                            placeholder="Tife Samuel" value={withdraw.account_name}   onChange={handleChangeWithdraw}
                                            className="form-control" required readOnly={withdraw.fetched? true : false}
                                            autoComplete={false}
                                            />
                                    </div>
                                    <div className="col-12 mt-2 margin">
                                        <label>Account Number</label> 
                                        <input type="text" id="acc" name="account_number"
                                            onChange={handleChangeWithdraw}
                                            value={withdraw.account_number}
                                            readOnly={withdraw.fetched? true : false}
                                            autoComplete={false}
                                            className="form-control" required
                                                />
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                    <label>Bank</label> 
                                    <select name="bank" className="form-control" 
                                         onChange={handleChangeWithdraw} value={withdraw.bank}
                                         readOnly={withdraw.fetched? true : false}
                                         autoComplete={false}>
                                            <option>First</option>
                                            <option >Polaris</option>
                                            <option>GTB</option>
                                            <option>Access</option>
                                            <option>UBA</option>
                                            <option>Zenith</option>
                                    </select> 
                                    </div>
                                    </div>     
                                    <div className="form-group text-right pb-3">
                                        <button type='submit' 
                                        disabled={withdraw.fetched? true : false}
                                        className='btn btn-md btn-login'>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                        </form>
                        {/* <Card.Footer>
                            <p className='card-text' dangerouslySetInnerHTML={{__html:w.text_prove}}></p>
                        </Card.Footer> */}
                        </Accordion.Collapse>
                    </Accordion>
                    </div>
                </div>
                <button className='btn btn-md' onClick={logout}>Logout</button>
                <Advert/>
        </div>
    )
}
