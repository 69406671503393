import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const Styles = styled.div`
     .jumbotron{
        background-color: #26534c !important;
        border-radius: 0px;
        color: #fff; 
        // height:600px
     }
     h4{
        font-weight: bolder;
        font-size: 40px;
     }
     p{
        font-size: 17px;
     }
     `
export default function Count() {
    const [counter, setCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (counter < 960) {
        setCounter(counter => counter + 1);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [counter]);
    return (
        <Styles>
            <div className='jumbotron mt-0' >
                <div className='container'>
                    <div className='row text-center'>
                        <div className='col-6  col-md-3 text-center'>
                            <h4>{counter}</h4>
                            <p>_____</p> 
                            <p>Tasks Posted</p>
                        </div>
                        <div className='col-6  col-md-3 text-center'>
                            <h4>{counter}</h4>
                            <p>_____</p> 
                            <p>Tasks Completed</p>
                        </div>
                        <div className='col-6  col-md-3 text-center'>
                            <h4>{counter}</h4>
                            <p>_____</p> 
                            <p>Freelancers</p>
                        </div>
                        <div className='col-6  col-md-3 text-center'>
                            <h4>{counter}</h4>
                            <p>_____</p> 
                            <p>Sellers</p>
                        </div>
                    </div>
                </div>
            </div>
        </Styles>
    )
}
