import React from 'react'
import styled from 'styled-components';
import './section.css'
export default function SectionOne() {
    return (
        <>
            <div className='container  mt-0 mb-4' >
                <center>
                    <h4 className='mb-4 service'>
                        <b>Our Services</b>
                    </h4>  
                </center>
                
                <div className='row  small' >
                    <div className='col-6'>
                        <h5 className='h5'>
                           <span><img src={'images/task List.svg'} alt='task List' className='mr-2'/> </span> Easy accessible task
                        </h5>
                        <p className='p'>Task are very simple and no educational skills are required to execute task</p>
                    </div>
                    <div className='col-6'>
                        <h5 className='h5'>
                           <span><img src={'images/referral.svg'} alt='referral' className='mr-2'/> </span> Referral Program
                        </h5>
                        <p className='p'>Share your unique link to earn 50% Referral bonus 
                        when user subscribed to a plan, 20% for sub-referral, 5% on task completion</p>
                    </div>
                </div>
                <div className='row  small' >
                    <div className='col-6'>
                        <h5 className='h5'>
                           <span><img src={'images/withdraw.svg'} alt='referral' className='mr-2'/> </span> Fast payment options
                        </h5>
                        <p className='p'>
                        We offer several withdrawal options to get your earnings to you quickly.
                        </p>
                    </div>
                    <div className='col-6'>
                        <h5 className='h5'>
                           <span><img src={'images/basket.svg'} alt='basket' className='mr-2'/> </span> Market
                        </h5>
                        <p className='p'>Gain-Market allows you to sell your products / services, earn cash back on sales </p>
                    </div>
                </div>
                <div className='row small img' style={{backgroundImage:"url('/images/background-image.svg')", 
                backgroundSize:'cover', backgroundPosition: "center bottom"}}>
                    <div className='col-6'>
                        <h5 className='h5'>
                           <span><img src={'images/membership.svg'} alt='Membership' className='mr-2'/> </span> Membership Plan
                        </h5>
                        <p className='p'>Our membership plans gives you easy access to create task, sell on market, and earn more on referrals</p>
                    </div>
                    <div className='col-6'>
                        <h5 className='h5'>
                           <span><img src={'images/clock.svg'} alt='clock' className='mr-2'/> </span> 24/7 Support
                        </h5>
                        <p className='p'>
                        We are ready to help in solving your problem during the day and night, just reach out to us
                        </p>
                    </div>
                    
                </div>

                {/* medium to big screen */}
                <div className='row big'>
                    <div className=' col-md-4'>
                        <h5 className='h5'>
                           <span><img src={'images/task List.svg'} alt='task List' className='mr-2'/> </span> Easy accessible task
                        </h5>
                        <p className='p'>Task are very simple and no educational skills are required to execute task</p>
                    </div>
                    <div className=' col-md-4'>
                        <h5 className='h5'>
                           <span><img src={'images/referral.svg'} alt='referral' className='mr-2'/> </span> Referral Program
                        </h5>
                        <p className='p'>Share your unique link to earn 50% Referral bonus 
                        when user subscribed to a plan, 20% for sub-referral, 5% on task completion</p>
                    </div>
                    <div className=' col-md-4'>
                        <h5 className='h5'>
                           <span><img src={'images/withdraw.svg'} alt='referral' className='mr-2'/> </span> Fast payment options
                        </h5>
                        <p className='p'>
                        We offer several withdrawal options to get your earnings to you quickly.
                        </p>
                    </div>
                    
                </div>
                <div className='row big'>
                    <div className=' col-md-4'>
                        <h5 className='h5'>
                           <span><img src={'images/basket.svg'} alt='basket' className='mr-2'/> </span> Market
                        </h5>
                    </div>
                    <div className=' col-md-4'>
                        <h5 className='h5'>
                           <span><img src={'images/membership.svg'} alt='Membership' className='mr-2'/> </span> Membership Plan
                        </h5>
                    </div>
                    <div className=' col-md-4'>
                        <h5 className='h5'>
                           <span><img src={'images/clock.svg'} alt='clock' className='mr-2'/> </span> 24/7 Support
                        </h5>
                    </div>
                    
                </div>
                <div className='row big' style={{backgroundImage:"url('/images/background-image.svg')", 
                backgroundSize:'cover', backgroundPosition: "center bottom", height:"200px"}}>
                    <div className=' col-md-4'>
                        <p className='p'>Gain-Market allows you to sell your products / services, earn cash back on sales </p>
                    </div>
                    <div className=' col-md-4'>
                        <p className='p'>Our membership plans gives you easy access to create task, sell on market, and earn more on referrals</p>
                    </div>
                    <div className=' col-md-4'>
                        <p className='p'>
                        We are ready to help in solving your problem during the day and night, just reach out to us
                        </p>
                    </div>
                    
                </div>
            </div>
        </>
    )
}
