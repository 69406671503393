import React, {useState, useRef, useEffect} from 'react';
// import { signUp } from '../../utils/postData';
import {Link, useHistory} from "react-router-dom";
import { signUp } from '../../../utils/postData';


export default function CreateAdmin() {
    const [state, setState]= useState({
        username: '',
        password:'',
        account_type: "Admin",
        name: '',
        email: ''
    })
    const history = useHistory();
    const [loginError, setLoginError]= useState('');
    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    
    const handleSubmit= async e=>{
        e.preventDefault()
        try {
            // dispatch({type: 'AUTH', data: {result}})
            const result = await signUp(state)
            
             if(result.data){
                localStorage.setItem("profile", JSON.stringify(result.data))
                history.push("/dashboard")                                   
             }

             
        } catch (error) {
            if(error.response){
                // console.log(error.response.data.message)
                setLoginError(error.response.data.message)
                // this.setState({loginError: error.response.data.message})
            }
            else{
                console.log(error)
            } 
        }
    }

  return (
    <div className='wrapper'>
        <div className='container'>
            <div className="text-center sign-up">
                <h4>Sign Up</h4>
                <h5>Create a new Admin</h5>
                <h6 className="text-danger">{loginError}</h6>
            </div>
            <div className='row'>
                <div className='col-lg-6 mx-auto'>
                    <form onSubmit={(values) => handleSubmit(values)} className=" needs-validation sign">
                        <div className='container'>
                            <div className="form-group row mt-4">
                                <div className="col-12 ">
                                    <label>Name</label>
                                    <input type="text" id="firstname" name="name"
                                        placeholder="Name"   onChange={handleChange}
                                        className="form-control" required
                                        />
                                </div>
                            </div>
                            <div className="form-group row">
                                <div className="col-12 margin">
                                    <label>Username</label> 
                                    <input type="text" id="username" name="username"
                                        placeholder="username" onChange={handleChange}
                                        className="form-control" required/>
                                </div>
                            </div>
                            <div className="form-group row mt-4">
                                <div className="col-12">
                                <label>Email</label> 
                                <input type="text"  id="email" name="email"
                                                    placeholder="Email" onChange={handleChange}
                                                    className="form-control" required/>
                                </div>
                            </div>
                            <div className="form-group row mt-4">
                                <div className="col-12">
                                    <label>Password</label>
                                        <input type="password"  id="password" name="password"
                                            placeholder="Password" onChange={handleChange}
                                            className="form-control" required
                                            />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-12'>
                                    <input type='checkbox' required/> <small>I have read and agree to <Link to='#'>Terms of privacy</Link> </small>
                                </div>
                            </div>
                                <div className="form-group text-right pt-2 pb-3">
                                    <button type='submit' className='btn btn-md btn-login w-100'>Submit</button>
                                </div>
                            </div>          
                        </form>
                </div>
            </div>
        </div>

    </div> 
  )
}
