import React from 'react'
import {Link} from "react-router-dom";
export default function SectionFour() {
    return (
        <div className='container  mt-2'>
            <div className='row'>
                <div className='col-12 offset-md-3 col-md-6 text-center'>
                    <h4>Reasons for choosing us</h4>
                    <p className='color'>
                         GainWorkers gives you the freedom of earning real money from variety of tasks. from referrals, cash back on sales, also pays you to share products links from Gain-Market to your social media platform. We also provide you
                         with variety of withdrawal options that are quick and secure. sign up and start earning now!
                    </p>
                </div>
            </div>
        </div>
    )
}
