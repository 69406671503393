import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

const Styles = styled.div`
     .jumbotron{
        background-color: #26534c !important;
        border-radius: 0px;
        color: #fff; 
        // height:600px
     }
     h4{
        font-weight: bolder;
        font-size: 40px;
     }
     p{
        font-size: 17px;
     }
     `
export default function Advert() {
    return (
        <Styles>

                <div className='container mb-3'>
                    <div className='row text-center'>
                        <div className='col-12 offset-md-3  col-md-6 text-center'>
                          <div className='card'>
                            <div className='card-body'>
                              <marquee>970X90</marquee>
                            </div>
                          </div>
                        </div>             
                    </div>
                </div>
        </Styles>
    )
}
