import React from 'react';
import styled from 'styled-components';
import Slider from "react-slick";


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "inline", background: "grey" }}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "grey" }}
        onClick={onClick}
      />
    );
  }
  const Styles = styled.div`
  // .back{

  // }
  `

export default function GetStarted() {
    const products = [
        {
          img: 'images/1.png',
          title: 'Dolore magna',
          text: 'Lorem ipsum dolor sit amet elit.'
        },
        {
          img: 'images/2.png',
          title: 'Eget est lorem',
          text: 'Lorem Ipsum adipiscing elit ipsum.'
        },
        {
          img: 'images/3.png',
          title: 'Tempus imperdiet',
          text: 'Orci porta non pulvinar neque laoreet.'
        },
        {
          img: 'images/4.png',
          title: 'Mattis rhoncus',
          text: 'Bibendum neque egestas congue quisque.'
        },
        {
          img: 'images/5.png',
          title: 'Odio ut enim',
          text: 'Mattis rhoncus urna neque viverra justo.'
        }
      ]
    const settings = {
        // dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
      };
    return (
        <Styles>
            <div className='container mt-4' id='getting-started'>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <h4>Getting Started</h4>    
                    </div>
                </div>
                <div className='row text-center big'>
                    <div className='col-12 offset-md-1 col-md-2'>
                        <img src={'images/1.png'} className='img-fluid'  alt='one'/>
                    </div>
                    <div className='col-12 col-md-2'>
                        <img src={'images/2.png'} className='img-fluid' alt='one'/>
                    </div>
                    <div className='col-12 col-md-2'>
                        <img src={'images/3.png'} className='img-fluid' alt='one'/>
                    </div>
                    <div className='col-12 col-md-2'>
                        <img src={'images/4.png'} className='img-fluid' alt='one'/>
                    </div>
                    <div className='col-12 col-md-2'>
                        <img src={'images/5.png'} className='img-fluid' alt='one'/>
                    </div>
                </div>
                <div className='row sm'>
                    <Slider {...settings}>
                    {products.map((x, i) => {
                        return <div key="{i}" classname="card text-center">
                        <img classname="img-card" height={'180px'} src={x.img}/>
                    
                        </div>
                    })}
                    </Slider>
                </div>
                <div className='row mt-0 back' style={{backgroundImage:"url('/images/background-image.svg')", 
                backgroundSize:'cover', backgroundPosition: "center bottom", height:"128px"}}>

                </div>
            </div>
        </Styles>
    )
}
