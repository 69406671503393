import React, { useState } from 'react';
import Slider from "react-slick";
import {Link} from "react-router-dom";
import styled from 'styled-components';

const Styles = styled.div`
  
        button{
          border: solid 1px #000 !important;
          background-color: #fff;
        }
        .card{
          background-color: grey !important;
        }
        .text-white{
          background-color: #26534c;
      }
        @media (max-width: 768px) {
  
          button{
            font-size: 10px;

          }
        }
        
    `

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "inline", background: "grey" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "grey" }}
      onClick={onClick}
    />
  );
}

export default function SectionFive() {
    
   const settings = {
      // dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 3,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />
    };
 
  const products = [
    {
      img: '/images/carousel-2.jpg',
      title: 'Dolore magna',
      text: 'Lorem ipsum dolor sit amet elit.'
    },
    {
      img: '/images/product2.jpg',
      title: 'Eget est lorem',
      text: 'Lorem Ipsum adipiscing elit ipsum.'
    },
    {
      img: '/images/product3.jpg',
      title: 'Tempus imperdiet',
      text: 'Orci porta non pulvinar neque laoreet.'
    },
    {
      img: '/images/product4.jpg',
      title: 'Mattis rhoncus',
      text: 'Bibendum neque egestas congue quisque.'
    },
    {
      img: '/images/product5.jpg',
      title: 'Odio ut enim',
      text: 'Mattis rhoncus urna neque viverra justo.'
    }
  ]

    return (
        <Styles>
        
            <div className="container mt-3">
               
            <div className='text-center'>
                <h4>Latest Products On Gain-Market</h4>
            </div>
                
            <Slider {...settings}>
            {products.map((x, i) => {
                    return <div key="{i}" classname="card">
                    <img classname="img-card" src="{x.img}"/>
                    <div class="card-body">
                        {/* <div classname="card-title">{x.title}</div>
                        <div classname="card-text">{x.text}</div> */}
                        <div className='text-center'>
                            <button className='btn btn-md'>SHOP NOW</button>
                        </div>
                    </div>
                    </div>
                })}
            </Slider>
            <div className='row mt-3'>
              <div className='col-12  text-center color'>
                  <p>Learn how we've helped several clients grow <br/> their businesses online. </p>
              </div>
              <div className=' col-12 text-center pb-3'>
                      <button className='text-white btn btn-sm'>Learn More</button>
              </div>
            </div>
        </div>
        </Styles>
    );
  }


const cssstyle = `

// h3 {
//     background: #5f9ea0;
//     color: #fff;
//     font-size: 36px;
//     line-height: 100px;
//     margin: 10px;
//     padding: 2%;
//     position: relative;
//     text-align: center;
// }
.slick-next:before, .slick-prev:before {
    color: #000;
}
`