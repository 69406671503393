import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export default function SectionTwo() {
    const Styles = styled.div`
        // .bg-color{
        //     background-color: #70AD46;
        //     color: white;
        // }  
        i{
            padding:3px;
            border: 1px solid;
            border-radius: 30px;
            color: #fff;
            background-color: #26534c;
            font-size: 7px
        }
        // .card-header{
        //     height:42px
        // }
        .card{
            border-radius: 0px
        }
        .btn-plan{
            background-color: #26534c;
        }
        .inside{
            border-radius: 36px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 1px #ece8d9;
            background-color: #fff
        }
        .btn-sm{
            border: solid 1px #707070;
        }
        
        .color-two{
            color: #102226 !important;
        }
        // .color .col-10{
        //     color: #102226 !important;
        //     font-size: 14px;
        // }
        `
    return (
        <Styles>
        
            <div className='container'>
                <div className='row text-center mt-4 mb-3'>
                    <div className='col-12'>
                        <h4>Membership</h4>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12 col-md-4 pl-0 pr-0'>
                        <div className='card mb-3' style={{height: '667px'}}>
                            <div className='card-body text-center'>
                                <h5>Basic Membership</h5>
                               <p className='color-two'>
                                  Basic membership account is a free plan that gives users instant access to tasks posted on platform, referral bonus, and so much more....
                               </p>
                               
                                <div className='card inside text-left mt-5 mb-5'>
                                    <div className='card-body'>
                                        <p><small>BASIC</small> </p>
                                        <h5>FREE</h5>
                                        <p>For independent workers</p>
                                        <p className='color'>Gain access to unlimited task daily with a free basic membership account</p>
                                        <hr/>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Lifetime Referral Bonus
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Lifetime Sub Referral Commission 
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Access To Unlimited Task
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Weekly Withdrawal + 24Hrs - 48Hrs Approved Time
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='/signup'>
                                                <button className='btn btn-sm btn-sign'>Sign Up Now</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 pl-0 pr-0'>
                        <div className='card mb-3'>
                            <div className='card-body text-center'>
                                <h5>Advanced Membership</h5>
                               <p className='color-two'>
                               Advanced membership account is a paid plan that allow user to create tasks on platform with selected options, 
                               apply for tasks, featured tasks, withdraw instantly and so much more...
                               </p>
                               
                                <div className='card inside text-left '>
                                    <div className='card-body'>
                                        <p><small>ADVANCE</small> </p>
                                        <h5>₦2,000</h5>
                                        <p><b>Advanced features for employers</b> </p>
                                        <p className='color'>Require workers? Post task and pay workers when satisfied.</p>
                                        <hr/>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            All Basic Membership Features
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Create Tasks with multiple selections
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Post Job Offer / Services
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Earn more promoting products on Gain-Market
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Instant Weekly Withdrawal
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            High referral's commission
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Membership Bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3 mb-2'>
                                            <Link to='account/upgrade'>
                                                <button className='btn btn-sm btn-sign'>Start Earning Now</button>
                                            </Link>
                                        </div>
                                        <div className='text-center mt-3 color'>
                                            <img src={"images/notification.svg"} alt='notification'/> <span>Lifetime Payments</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4 pl-0 pr-0 one'>
                        <div className='card mb-3' style={{height: '667px'}}>
                            <div className='card-body text-center'>
                                <h5>Enterprise</h5>
                               <p className='color-two'>
                               Expand your offering by selling products and services on Gain-Market 
                               using our simple tools to promote and maximize your business into a larger scale
                               </p>
                               
                                <div className='card inside text-left mb-3'>
                                    <div className='card-body'>
                                        <p><small>ENTERPRISE</small> </p>
                                        <h5>₦8,500</h5>
                                        <p><b>Seller's Plan</b></p>
                                        <p className='color'>Gain access to unlimited task daily with a free basic membership account</p>
                                        <hr/>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            All Basic Membership Features
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            All Advance Membership Features 
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Huge Referral & Membership Bonus
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Free Product Marketing & Ads On Gainworker Market
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Dedicated Success Manager
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            24hr Support
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                            <Link to='account/upgrade'>
                                                <button className='btn btn-sm btn-sign'>Start Selling</button>
                                            </Link>
                                        </div>
                                        <div className='text-center mt-3 color'>
                                            <img src={"images/notification.svg"} alt='notification'/> <span>Yearly Recurring Payments</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                       
                
                </div>
            </div>
        </Styles>
    )
}
