import React, {useState, useEffect} from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import axios from 'axios';
import { requestWithdraw } from '../../utils/postData';
export default function Withdrawal() {
    const [withdraw, setWithdraw] = useState({
        bank: "",
        account_name:'',
        account_number: '',
        amount: '',
        taskBalance: 0
    })
    const[message, setMessage] = useState('')

    useEffect(()=>{
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        axios.get(`${base_url}/users/profile`).then(res=>{
            setWithdraw({
                bank: res.data.withdrawal.bank,
                account_name: res.data.withdrawal.account_name,
                account_number: res.data.withdrawal.account_number,
                taskBalance: res.data.taskBalance
            })
            
        })
    }, []);
    const handleChangeWithdraw = (e)=>{
        const value = e.target.type==='checkbox' ? e.target.checked: e.target.value
        setWithdraw({
            ...withdraw,
            [e.target.name]:  value,
        })
    }
    const handleWithdrawal = e =>{
        e.preventDefault()
        let formData = withdraw
        requestWithdraw(formData).then(res=>{
            setMessage(res.data)
            setWithdraw({amount : ''})
        }).catch(err=>{
            if(err.response){
                setMessage(err.response.data)
            }
        })
    }
  return (
    <div className='container after'>
        <h4 className='mb-4'><b>Withdraw</b></h4>
        <div className='row mb-4'>
            <div className='col-md-6 col-12 mx-auto offset-1 banner text-center'>
                <h6>
                <img src={"images/notification.svg"} alt='notification' /> 
                <span className='ml-1 '>
                    Make sure to have updated your withdrawal details on your dashboard.
                </span>
                </h6>
                {/* <p className='text-center'>{message} <span style={{color:'red'}}>{error}</span> </p> */}
            </div>
        </div>
        <div className="row pb-4">
            <div className="col-lg-6 mx-auto">
                <div className="card ">
                    <div className='card-header'>
                        <Tabs
                            defaultActiveKey="home"
                            transition={false}
                            id="noanim-tab-example"
                            className="mb-3"
                            >
                            <i class="fas fa-credit-card mr-2"></i> <Tab eventKey="home" title="Bank Transfer">
                                    <p className='text-center'>{message}</p>
                                    <form role="form" onSubmit={(values) => handleWithdrawal(values)} className='sign'>
                                        <div className="form-group"> 
                                            <label for="name">
                                                <h6>Account Name</h6>
                                            </label> 
                                            <input type="text" id="firstname" name="account_name"
                                            placeholder="Tife Samuel" value={withdraw.account_name}   onChange={handleChangeWithdraw}
                                            className="form-control" required readOnly={true}
                                            />
                                        </div>
                                        <div className="form-group"> 
                                            <label for="cardNumber">
                                                <h6>Account Number</h6>
                                            </label>
                                            <input type="text" id="acc" name="account_number"
                                            onChange={handleChangeWithdraw}
                                            value={withdraw.account_number}
                                            readOnly={true}
                                            className="form-control" required
                                                />
                                        </div>
                                        <div className="form-group"> 
                                            <label for="cardNumber">
                                                <h6>Bank</h6>
                                            </label>
                                            <input type="text" name="bank" className="form-control" 
                                                onChange={handleChangeWithdraw} value={withdraw.bank}
                                              readOnly={true} required
                                                />
                                        </div>
                                        <div className="form-group row"> 
                                                <div className="col-sm-8">
                                                    <label>
                                                        <h6>Amount</h6>
                                                    </label>
                                                    <input type="number" onChange={handleChangeWithdraw} value={withdraw.amount}
                                                    placeholder="Amount" name="amount"  className="form-control" required/> 
                                                </div>
                                            
                                                <div className="col-sm-4 margin">             
                                                    <label data-toggle="tooltip" className='' title="Three digit CV code on the back of your card">
                                                            <h6>Balance</h6>
                                                    </label> 
                                                    <input type="text" required className="form-control" value={withdraw.taskBalance} readOnly={true}/> 
                                                </div>
                                        </div>
                                        <div className="card-footer"> 
                                            <button className="subscribe btn btn-login btn-block shadow-sm" > Submit Withdrawal </button>
                                        </div>
                                    </form>
                            </Tab>
                            <Tab eventKey="profile" title="Paypal" disabled>
                                A text
                            </Tab>
                        </Tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
        
      
      
      )
    }

{/* <div className='container after'>
            <div className="row">
                <div className="col-lg-6 mx-auto">
                    <div className="card ">
                        <div className='card-header'>
                            <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example" className="mb-3">
                                <Tab eventKey="home" title="Bank Transfer">
                                    <form role="form" onsubmit="event.preventDefault()">
                                        <div className="form-group"> <label for="username">
                                                <h6>Card Owner</h6>
                                            </label> <input type="text" name="username" placeholder="Card Owner Name" required className="form-control "> </div>
                                        <div className="form-group"> <label for="cardNumber">
                                                <h6>Card number</h6>
                                            </label>
                                            <div className="input-group"> <input type="text" name="cardNumber" placeholder="Valid card number" className="form-control " required>
                                                <div className="input-group-append"> <span className="input-group-text text-muted"> <i className="fab fa-cc-visa mx-1"></i> <i className="fab fa-cc-mastercard mx-1"></i> <i className="fab fa-cc-amex mx-1"></i> </span> </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-8">
                                                <div className="form-group"> <label><span className="hidden-xs">
                                                            <h6>Expiration Date</h6>
                                                        </span></label>
                                                    <div className="input-group"> <input type="number" placeholder="MM" name="" className="form-control" required> <input type="number" placeholder="YY" name="" className="form-control" required> </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="form-group mb-4"> <label data-toggle="tooltip" title="Three digit CV code on the back of your card">
                                                        <h6>CVV <i className="fa fa-question-circle d-inline"></i></h6>
                                                    </label> <input type="text" required className="form-control"> </div>
                                            </div>
                                        </div>
                                        <div className="card-footer"> <button type="button" className="subscribe btn btn-primary btn-block shadow-sm"> Confirm Payment </button>
                                    </form>
                                </Tab>
                                <Tab eventKey="profile" title="Paypal" disabled>
                                    <p>A text</p>
                                </Tab>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}