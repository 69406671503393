import React, { Component } from 'react';
import { CKEditor, CK } from 'ckeditor4-react';
import { postJob } from '../../utils/postData';
import {Spinner} from 'react-bootstrap';
import axios from 'axios';

export default class PostJobs extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            title:"",
            category:"Social Media",
            subCategory: "Like and Share",
            workers:0,
            amount:0,
            calc: 0 ,
            avatar:null,
            total: 0,
            depositBalance:0,
            sponsored: 0,
            bold: 0,
            proof:'',
            description:'',
            message:'',
            error:'',
            previewImage:undefined,
            loading: false
        }
    }
    componentDidMount(){
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        axios.get(`${base_url}/job/balance`).then(res=>{
           this.setState({depositBalance: res.data.balance})
        }) 
        
    }
    handleCalculate=()=>{
        if(this.state.amount && this.state.workers){
         this.setState({
            calc: this.state.workers * this.state.amount
         })
        }
     }
     handleChange = (e)=>{
        const value = e.target.type==='checkbox' ? e.target.checked: e.target.value
        this.setState({
            ...this.state,
            [e.target.name]:  e.target.value,
        },()=> this.handleCalculate())
    }
    handleCheck = (e)=>{
        if (e.target.checked ) {
            this.setState({
                sponsored: 85,
                // total: [...this.state.calc,this.state.calcTemp, e.target.value]
            })
        }
        
        else{
            this.setState({
                sponsored:  0,
                // total: this.state.calc.filter(c=> c !== e.target.value) 
            })
        }
    }
    handleCheckTwo = (e)=>{
        if (e.target.checked) {
            this.setState({
                bold:  35,
                // total: [...this.state.calc,this.state.calcTemp,e.target.value]
            })
        }
    
        else {
            this.setState({
                bold:  0,
                // total: this.state.calc.filter(c=> c !== e.target.value)
            })
        }
        
    }
    onFileChange = (e)=>{
        this.setState({
            ...this.state,
            avatar: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0])
        })
    }
    onEditorChange=( evt ) =>{
        this.setState( {description: evt.editor.getData()});
    }
    onEditorChangeTwo=( evt ) =>{
        this.setState( {proof: evt.editor.getData()});
    }
    handleProcess= (e)=>{
        e.preventDefault();
        this.setState({
            loading: !this.state.loading
        })
        let formData = new FormData();
        formData.append('avatar',this.state.avatar);
        formData.append('title',this.state.title);
        formData.append('category',this.state.category);
        formData.append('subCategory',this.state.subCategory);
        formData.append('workers',this.state.workers);
        formData.append('amount',this.state.amount);
        formData.append('calc',this.state.calc);
        formData.append('depositBalance',this.state.depositBalance);
        formData.append('proof',this.state.proof);
        formData.append('description',this.state.description);
        formData.append('bold',this.state.bold);
        formData.append('sponsored',this.state.sponsored);
        postJob(formData).then(result=>{
        this.setState({message: result.data.message, error:'', title:'', loading: !this.state.loading})
        }).catch(error=>{
            if(error.response){
                this.setState({error: error.response.data.message, message: '', loading: !this.state.loading}) 
                }
            else{
                console.log(error)
            }
        })

    }
    
    render() {
        return (
            <div className='container after post'>
            <h4 className='mb-4'><b>Post new task</b></h4>
            <div className='row'>
                <div className='col-10 offset-1 banner'>
                    <p>
                    <img src={"images/notification.svg"} alt='notification' /> 
                    <span className='ml-1'>
                     All submitted tasks are reviewed for approval, before being published . Make sure to provide all details concerning the tasks, as well as easy-to-follow methods.
                    </span>
                    </p>
                    <h6 className='text-center text-success'>{this.state.message.toLocaleUpperCase()} <span style={{color:'red'}}>{this.state.error}</span> </h6>
                </div>
            </div>
            <form  onSubmit={(values) => this.handleProcess(values)} className="mb-4 post">
                <div className='row mt-4'>
                    <div className='col-12 col-md-8'>
                        <div className="form-group row mt-4">
                            <div className="col-md-6 col-12 ">
                                <label><b>Task category</b></label>
                                <select name="category" className="form-control" 
                                    value={this.state.category}  onChange={this.handleChange}>
                                        <option>Social Media</option>
                                        <option >Ratings / Review</option>
                                        <option>Voting</option>
                                        <option >Seo Ranking</option>
                                        <option >Download Mobile App</option>
                                        <option>Website Sign up</option>
                                </select> 
                            </div>  
                            <div className="col-md-6 col-12 margin">
                                <label><b>Workers</b> <small>(Min: 10 workers)</small></label> 
                                <input type="number" name="workers" className="form-control" 
                                    min="10" max="20" onChange={this.handleChange}  required/>
                            </div>
                        </div>
                        <div className="form-group row mt-4">
                            <div className="col-md-6 col-12 ">
                                <label><b>Task Sub Category</b></label>
                                <select name="subCategory" value={this.state.subCategory} 
                                className="form-control" onChange={this.handleChange}>
                                    <option >Like and Share</option>
                                    <option >Comment</option>
                                    <option >Follow or Subscribe</option>
                                    <option >Others</option>
                                </select>
                            </div>
                            <div className="col-md-6 col-12 margin">
                                <label><b>Earning</b> <small>(Each worker)</small></label> 
                                <input type="text" name="amount" className="form-control" placeholder="10"
                                    onChange={this.handleChange}  required/> 
                            </div>
                        </div> 
                        <div className="form-group row mt-4">
                            <div className="col-md-6 col-12">
                                <label><b>Task Title</b> <small>( Max: 40 texts )</small></label>
                                <input type="text" name="title" className="form-control" 
                                    onChange={this.handleChange} value={this.state.title} required/> 
                            </div>
                            <div className="col-md-6 col-12">
                                    <label className='label'><b>Available Funds</b></label>
                                    <input type="text" name="" className="form-control" value={this.state.depositBalance} readOnly ={true}/>
                            </div>
                        </div>
                        <div className="form-group row">
                                <div className="col-12">
                                <label><b>Task Instructions</b> <small>( Max: 1500 texts )</small></label>
                                    
                                    <CKEditor
                                        data={this.state.description}
                                        rows='6'
                                        onChange={this.onEditorChange}
                                        config={ {
                                            toolbar: [ [ 'Bold','Link' ] ],
                                            height: '100px'
                                        } }
                                    required/>
                                    
                                </div>
                        </div>
                        <div className="form-group row pb-5">
                                <div className="col-12">
                                <label><b>Required Proof</b> <small>(Input text or upload an image)</small></label>
                                    <CKEditor
                                        data={this.state.proof}
                                        rows='6'
                                        onChange={this.onEditorChangeTwo}
                                        style={{
                                            'height': '113px',
                                        }}
                                        
                                        config={ {
                                            toolbar: [ [ 'Bold','Link' ] ],
                                            height: '100px'
                                        } }
                                    required/>
                                    
                                </div>
                        </div>
                        <div className="form-group row">
                            {this.state.avatar?
                                <div>
                                    <img className="preview m-4" 
                                    src={this.state.previewImage} 
                                    alt="preview" height='100'
                                    width='150' />
                                </div> : <></>
                            }
                            <div className="col-12">
                                <label for="files" class="btn btn-md btn-secondary">Select Screenshot</label>
                                <input type='file' 
                                accept="image/*" 
                                id="files"
                                style={{visibility:"hidden"}}  
                                onChange={this.onFileChange}/>       
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-4'>
                        <p className='mt-4'>
                        <b>Added Features</b>
                        </p>
                        <p>Make your task standout and eye catchy by 
                        sponsoring task, make your text bolder for quick view</p>
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <input type='checkbox' 
                                        value= {85} 
                                        onChange={this.handleCheck}/> <b>Sponsored Task</b> 
                                    </div>
                                    <div className='col-6 text-right'>
                                    ( N85.00 )
                                    </div>
                                </div>
                                <small>Sponsored Tasks will be displayed randomly at the top of the task page and on home page</small>
                            </div>
                        </div>
                        <div className='card mt-4'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-6'>
                                        <input type='checkbox' name='bold' 
                                        onChange={this.handleCheckTwo} value={35} /> <b>Bold Text</b> 
                                    </div>
                                    <div className='col-6 text-right'>
                                    ( N35.00 ) 
                                    </div>  
                                </div>
                                <small>Add bold feature to make task title bolder</small>
                            </div>
                        </div>
                        <p className='mt-3'><b>Task Cost:</b> {this.state.calc} </p>
                        <div className="col-12 pb-3">
                                <button className="btn btn-md btn-login" >
                                {this.state.loading ? (
                                                    <Spinner className='border'/>
                                                ): <>Submit Task</>}
                                </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        )
    }
}
