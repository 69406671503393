import React, {useState,useEffect} from 'react';
import styled from 'styled-components';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import {useHistory, useLocation, Link} from "react-router-dom";
import {updatePlan} from "../../../utils/postData"
import axios from 'axios';

const Styles = styled.div`
        // .bg-color{
        //     background-color: #70AD46;
        //     color: white;
        // }  
        i{
            padding:3px;
            border: 1px solid;
            border-radius: 30px;
            color: #fff;
            background-color: #26534c;
            font-size: 7px
        }
        // .card-header{
        //     height:42px
        // }
        .card{
            border-radius: 0px
        }
        .btn-plan{
            background-color: #26534c;
        }
        .inside{
            border-radius: 36px;
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border: solid 1px #ece8d9;
            background-color: #fff
        }
        .btn-sm{
            border: solid 1px #707070;
        }
        
        .color-two{
            color: #102226 !important;
        }
        // .color .col-10{
        //     color: #102226 !important;
        //     font-size: 14px;
        // }
        `

export default function UpgradePlan() {
    const history = useHistory();
    const location = useLocation();
    const [state, setState]= useState({
            name:"",
            email:"",
            phonenumber:"",
            currency: "NGN",
            success: ""
        })
    useEffect(()=>{
        
        const session_store= JSON.parse(localStorage.getItem('profile'));
        if(!session_store) {
            return history.push({
                pathname: '/login',
                state: { from: location }
              })
        }
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        axios.get(`${base_url}/users/profile`).then(res=>{
            // console.log(res.data)
            setState({name: res.data.username, 
                    email: res.data.email,
                    phonenumber: res.data.telephone || ''
                })
        })
    }, []);
    const config = {
        public_key: 'FLWPUBK_TEST-39aa8b1b296016b65cc940693371e9e6-X',
        // public_key: 'FLWPUBK_TEST-3bdedb39b6dcf9eeac299ae10a8a2b92-X',
        tx_ref: Date.now(),
        amount: '2000',
        currency: "NGN",
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: state.email,
          phonenumber: state.telephone,
          name: state.name,
        },
        customizations: {
          title: 'Gainworker',
          description: 'Make deposit now',
          
          logo: 'images/logo.png',
        },
      };
    const configTwo = {
        public_key: 'FLWPUBK_TEST-39aa8b1b296016b65cc940693371e9e6-X',
        // public_key: 'FLWPUBK_TEST-3bdedb39b6dcf9eeac299ae10a8a2b92-X',
        tx_ref: Date.now(),
        amount: '8500',
        currency: "NGN",
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: state.email,
          phonenumber: state.telephone,
          name: state.name,
        },
        customizations: {
          title: 'Gainworker',
          description: 'Make deposit now',
          
          logo: 'images/logo.png',
        },
      };
    const handleFlutterPayment = useFlutterwave(config);
    const handleFlutterPaymentTwo = useFlutterwave(configTwo);
    return (
        <Styles>
        
            <div className='container after'>
                <div className='row text-center mt-4 mb-3'>
                    <div className='col-12'>
                        <h4>Membership Upgrade</h4>
                    </div>
                    <div className='col-12'>
                    <p>{state.success}</p>
                    </div>
                    
                </div>
                <div className='row'>
                    <div className='col-12 col-md-6 one' >
                        <div className='card mb-3' style={{height: '667px'}}>
                            <div className='card-body text-center'>
                                <h5 className='pt-3'>Advanced Membership</h5>
                               <p className='color-two'>
                               Advanced membership account is a paid plan that allow user to create tasks on platform with selected options, 
                               apply for tasks, featured tasks, withdraw instantly and so much more...
                               </p>
                               
                                <div className='card inside text-left '>
                                    <div className='card-body'>
                                        <p><small>ADVANCE</small> </p>
                                        <h5>₦2,000</h5>
                                        <p><b>Advanced features for employers</b> </p>
                                        <p className='color'>Require workers? Post task and pay workers when satisfied.</p>
                                        <hr/>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            All Basic Membership Features
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Create Tasks with multiple selections
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Post Job Offer / Services
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Earn more promoting products on Gain-Market
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Instant Weekly Withdrawal
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            High referral's commission
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Membership Bonus
                                            </div>
                                        </div>
                                        <div className='text-center mt-3 mb-2'>
                                            <button className='btn btn-sm btn-sign' onClick={
                                                () => {
                                                    handleFlutterPayment({
                                                      callback: (response) => {
                                                        //  console.log(response);
                                                          closePaymentModal() // this will close the modal programmatically
                                                          const formData ={transaction_id: response.transaction_id}
                                                            updatePlan(formData).then(res=>{

                                                                    setState({success: res.data.message})
                                                            })
                                                      },
                                                      onClose: () => {},
                                                    });
                                                  }
                                            }>Start Earning Now</button>
                                        </div>
                                        <div className='text-center mt-3 color'>
                                            <img src={"images/notification.svg"} alt='notification'/> <span>Lifetime Payments</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6  one'>
                        <div className='card mb-3' style={{height: '667px'}}>
                            <div className='card-body text-center'>
                                <h5 className='pt-3'>Enterprise Membership</h5>
                               <p className='color-two'>
                               Expand your offering by selling products and services on Gain-Market 
                               using our simple tools to promote and maximize your business into a larger scale...
                               </p>
                               
                                <div className='card inside text-left mb-3'>
                                    <div className='card-body'>
                                        <p><small>ENTERPRISE</small> </p>
                                        <h5>₦8,500</h5>
                                        <p><b>Seller's Plan</b></p>
                                        <p className='color'>Gain access to unlimited task daily with a free basic membership account</p>
                                        <hr/>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            All Basic Membership Features
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            All Advance Membership Features 
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Huge Referral & Membership Bonus
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Free Product Marketing & Ads On Gainworker Market
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            Dedicated Success Manager
                                            </div>
                                        </div>
                                        <div className='row color'>
                                            <div className='col-1'>
                                                <i class="fas fa-check"></i>
                                            </div>
                                            <div className='col-10'>
                                            24hr Support
                                            </div>
                                        </div>
                                        <div className='text-center mt-3'>
                                        <button className='btn btn-sm btn-sign' onClick={
                                                () => {
                                                    handleFlutterPaymentTwo({
                                                      callback: (response) => {
                                                        //  console.log(response);
                                                          closePaymentModal() // this will close the modal programmatically
                                                          const formData ={transaction_id: response.transaction_id}
                                                            updatePlan(formData).then(res=>{
                                                                setState({success: res.data.message})
                                                            })
                                                      },
                                                      onClose: () => {},
                                                    })}
                                        }>Start Selling</button>
                                        </div>
                                        <div className='text-center mt-3 color'>
                                            <img src={"images/notification.svg"} alt='notification'/> <span>Yearly Recurring Payments</span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </Styles>
        
    )
}
