import React, {useState, useRef, useEffect} from 'react';
import { signUp } from '../../utils/postData';
import {Link, useHistory} from "react-router-dom";
import Header from '../Header';

export default function Signup(props) {
    const [state, setState]= useState({
        username: '',
        password:'',
        referral: props.referral || '',
        // account_type: "Admin",
        name: '',
        email: '',
        re_pass:''
    })
    const history = useHistory();
    useEffect(()=>{
        localStorage.clear('profile')
    }, [])
    // const dispatch = useDispatch();
    // const [user, setUser]= useState('');
    // const [message, setMessage]= useState('');
    const [loginError, setLoginError]= useState('');
    const handleChange = (e)=>{
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }
    
    const handleSubmit= async e=>{
        e.preventDefault()
        if(state.re_pass !== state.password){
            return setLoginError('PASSWORD DOES NOT MATCH')
        }
        try {
            // dispatch({type: 'AUTH', data: {result}})
            const result = await signUp(state)
            if(result.data){
            localStorage.setItem("profile", JSON.stringify(result.data))
            history.push("/profile")                                   
            }

            } catch (error) {
                if(error.response){
                    setLoginError(error.response.data)
                    // this.setState({loginError: error.response.data.message})
                }
                else{
                    console.log(error)
                } 
            }
 }
    return (
        <section>
                <div className=' container'>
                    <div className="text-center sign-up">
                        <h4>Sign Up</h4>
                        <h5>Become a member of Gainworker</h5>
                        <h6 className="text-danger">{loginError}</h6>
                    </div>
                    <div className='card'>
                        <form onSubmit={(values) => handleSubmit(values)} className=" needs-validation sign">
                                <div className='container'>
                                    <div className="form-group row mt-4">
                                        <div className="col-md-6 col-12 ">
                                            <label>
                                                <div>
                                                    Name
                                                </div>
                                            </label>
                                            <input type="text" id="firstname" name="name"
                                                placeholder="Name"   onChange={handleChange}
                                                className="form-control" required
                                                />
                                        </div>
                                        <div className="col-md-6 col-12 margin">
                                            <label className='sd'>Username</label> 
                                            <input type="text" id="username" name="username"
                                                placeholder="username" onChange={handleChange}
                                                className="form-control" required/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-md-6 col-12">
                                        <label>Email</label> 
                                        <input type="text"  id="email" name="email"
                                                            placeholder="Email" onChange={handleChange}
                                                            className="form-control" required/>
                                        </div>
                                        <div className="col-md-6 col-12">
                                                < label for='ref' className='sd'>Referral</label>
                                                <input type="text"   name="referral" 
                                                        placeholder="Referral(Optional)" value={props.referral}
                                                        className="form-control" readOnly={true}/>
                                        </div>
                                        </div>
                                            <div className="form-group row">
                                                    <div className="col-md-6 col-12">
                                                    <label>Password</label>
                                                        <input type="password"  id="password" name="password"
                                                            placeholder="Password" onChange={handleChange}
                                                            className="form-control" required
                                                            />
                                                    </div>
                                                <div className="col-md-6 col-12 margin">
                                                    <label className='sd'>Confirm Password</label>
                                                    <input type="password"  id="re_pass" name="re_pass"
                                                            placeholder="Confirm Password" onChange={handleChange}
                                                            className="form-control" required
                                                            />
                                                </div>
                                            </div>
                                            <div className='row text-center'>
                                                <div className='mx-auto col-12'>
                                                        <input type='checkbox' required/> <small>I have read and agree to <Link to='#'>Terms of privacy</Link> </small>
                                                </div>
                                                <div className='mx-auto col-12'>
                                                    <small>Already have an account? <Link to='login'>Login</Link> </small>
                                                </div>
                                            {/* <div className='col-md-6 col-12'>
                                                    <small>Already have an account? <Link to='login'>Login</Link> </small>
                                            </div> */}
                                            </div>
                                                
                                            <div className="form-group text-right pt-2 pb-3">
                                                <button type='submit' className='btn btn-md btn-login'>Submit</button>
                                            </div>
                                    </div>
                        </form>
                    </div>
                </div>
                {/* {props.referral && <Footer/>} */}
           </section>
    )
}
