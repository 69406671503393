import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import styled from 'styled-components';

const Styles = styled.div`
            a{
                color: black
            }
            p{
                font-size: 14px
            }
            a:hover{
                text-decoration: none;
            }
            .card{
                border-radius: 0px;
            }
            a:hover .card{
                background: rgb(250, 248, 248) !important;
            }
            a:hover .card button{
                display: inline !important;
            }
            .search h6{
                font-size: 16px;
            }
            
            @media (max-width: 768px) {
                .btn-success{
                    font-size:12px;
                }
            }
        `
export default function AllJob() {
  const[allJob, setAllJob]= useState([])
  const [filterParam, setFilterParam] = useState('All');
  const [filterCategory, setFilterCategory] = useState('All');
  
    useEffect(()=>{
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        axios.get(`${base_url}/job/myjob`).then(res=>{
          setAllJob(res.data.myJob)
        }).catch(err=>{
            console.log(err)
        })
    }, [])

    const search=(e)=>{
      if (filterCategory != 'All' &&  filterParam != 'All') {
        const fill = allJob.filter(d=> d.category== filterCategory).filter(d=> d.status == filterParam)
        setAllJob(fill)
      } else if(filterCategory != 'All') {
        const fill = allJob.filter(d=> d.category== filterCategory);
        setAllJob(fill)
      }
      else if(filterParam != 'All'){
        const fill = allJob.filter(d=> d.status == filterParam);
        setAllJob(fill)
      }
      else{
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        axios.get(`${base_url}/job/myjob`).then(res=>{
          console.log(res.data)
          setAllJob(res.data.myJob)
        }).catch(err=>{
            console.log(err)
        })
      }
    }
    
    const Jobs  = allJob.map(job=>{
      return(
          <div className='col-12' key={job._id}>
              <Link to={`postedTask/proof/${job._id}`}>           
                  <div className='card'>
                      <div className='card-body'>
                          <div className='row'>
                              <div className='col-4 text-center align-self-center'>
                                  <h6 className=''>{job.title} </h6>
                              </div>
                              <div className='col-3 text-center align-self-center'>
                                      <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>
                                      {job.count}/{job.workers} </h6>
                              </div>
                              <div className='col-3 align-self-center'>
                                      <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>{`${job.amount}.00`} </h6>
                              </div>
                              <div className='col-2 align-self-center text-center'>
                                  {job.status}
                              </div>
                          </div>
                          
                          {/* <span><small>{job.category} </small></span>
                          
                          <span className='ml-3'> <small>{job.subCategory}</small></span> */}
                      </div>
                  </div>
              </Link>
          </div>
            )

          })
    const tableContent  = allJob.map(job=>{
      return(
            <tr key={job._id}>
                    <td>{job.title}</td>
                    <td>{job.count}/{job.workers}</td>
                    <td>{`${job.amount}.00`}</td>
                    <td>{job.status} <span><Link  to={`postedTask/proof/${job._id}`}><button className='btn-login'>view</button></Link></span></td>
                
            </tr> 
            )

          })
  return (
        <Styles>

            <div className='container after'>
            <div className='row mb-4'>
                <div className='col-12'>
                <div className='card search'>
                    <div className='row m-3 big'>
                        <div className='col-2 align-self-center'>
                            <h6><b>Categories: </b></h6>
                        </div>
                        <div className='col-3'>
                            <select name="category" className="form-control" onChange={e=> setFilterCategory(e.target.value)}>
                                    <option value='All'>All category</option>
                                    <option>Social Media</option>
                                    <option >Ratings / Review</option>
                                    <option>Voting</option>
                                    <option >Seo Ranking</option>
                                    <option >Download Mobile App</option>
                                    <option>Website Sign up</option>
                            </select> 
                        </div>
                        <div className='offset-2 col-1 align-self-center'>
                            <h6><b>Status:</b></h6>
                        </div>
                        <div className='col-3'>
                            <select name="category" className="form-control" onChange={(e)=> setFilterParam(e.target.value)}>
                                    <option value='All'>All</option>
                                    <option value='Accepted'>Accepted</option>
                                    <option value='Rejected'>Rejected</option>
                                    <option value='Pending'>Pending</option>
                            </select> 
                        </div>
                        <div className='col-1'>
                            <button className='btn btn-md btn-login' onClick={search}>Search</button>
                        </div>
                    </div>

                    {/* Small screen size */}
                    <div className='container '>
                        <div className='row mt-3 small '>
                            <div className='col-4'>
                                <h6><b>Categories: </b></h6>
                            </div>
                            <div className='col-8 align-self-center'>
                                <select name="category" className="form-control" onChange={e=> setFilterCategory(e.target.value)}>
                                        <option value='All'>All Categories</option>
                                        <option>Social Media</option>
                                        <option >Ratings / Review</option>
                                        <option>Voting</option>
                                        <option >Seo Ranking</option>
                                        <option >Download Mobile App</option>
                                        <option>Website Sign up</option>
                                </select> 
                            </div>
                        </div>
                        <div className='row mt-2 small'>
                            <div className=' col-4'>
                                <h6><b>Status:</b></h6>
                            </div>
                            <div className='col-8 align-self-center'>
                                <select name="category" className="form-control" onChange={(e)=> setFilterParam(e.target.value)}>
                                        <option value='All'>All</option>
                                        <option value='Accepted'>Accepted</option>
                                        <option value='Rejected'>Rejected</option>
                                        <option value='Pending'>Pending</option>
                                </select> 
                            </div>
                        </div>
                        <div className='row mt-2 mb-3 small'>
                            <div className='col-12'>
                                <button className='btn btn-md btn-login' onClick={search}>Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div className='row mb-3 big'>
                    <div className='col-12'>
                        <div className='card grey'>
                            <div className='card-body pt-2 mt-0'>
                                <div className='row '>
                                    <div className='col-md-4 text-center'>
                                        <h6><b>Task Title</b></h6>
                                    </div>
                                    <div className='col-md-3 text-center'>
                                        <h6><b>Task Progress</b></h6>
                                    </div>
                                    <div className='col-md-3'>
                                        <h6><b>Earn Per task</b></h6>
                                    </div>
                                    <div className='col-md-2 text-center'>
                                        <h6><b>Status</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row big">
                    {Jobs}
                </div>
            </div>
            {/* Small screen size */}

            <div className='row small'>
                <div className='col-12'>
                    <table className="table table-responsive">
                        <thead className='grey'>
                            <tr>
                                <th>Task Title</th>
                                <th>Task Progress</th>
                                <th>Earn Per task</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tableContent}
                        </tbody>
                    </table>
                </div>
            </div>
        </Styles>
  )
}
