import React, {useEffect, useState} from 'react';
import {proofUpdate, verifyJobDone} from '../../utils/postData';
import Header from '../Header';
import Footer from '../Footer';
import {Accordion, Card} from "react-bootstrap";
import {   Modal,ModalHeader, ModalBody, ModalFooter } from 'reactstrap';


export default function CheckJobProof({id}) {
    const[data, setData] = useState([]);
    const[userId, setUserId] = useState("");
    const[message, setMessage] = useState("");
    const[job, setJob] = useState({
      amount:'',
      description:'',
      screenshot:'',
      reason:'',
      proof: '',
      title:'',
      category:'',
    });
    
    const[ModalOpen, setModalOpen] = useState(false);
    const[text, setText] = useState("");

    useEffect(()=>{
      let formData = {id}
        verifyJobDone(formData).then(res=>{
            setData(res.data.message)
            setJob({
              amount: res.data.amount,
              reason: res.data.reason,
              description: res.data.description,
              screenshot: res.data.screenshot,
              proof: res.data.proof,
              title: res.data.title,
              category: res.data.category,
            })
        }).catch(err=>{
            console.log(err)
        })
    }, [])
    const toggle = (id) => {
          setModalOpen(!ModalOpen)
          setUserId(id)
    };
    const handleAccept = async(id)=>{
      const formData = {status: "Accepted", id, amount: job.amount};
      try {
        const result = await proofUpdate(formData)
         setMessage(result.data.message)
         window.location.reload()
    } catch (error) {
        if(error.response){
          setMessage('something went wrong')
        }
        else{
            console.log(error)
        }
    }
    }
    const handleReject= async(e)=>{
      e.preventDefault()
    const formData = {status: "Rejected", id: userId, amount: job.amount, reason:text }
    try {
        const result = await proofUpdate(formData)
         setMessage(result.data.message)
         setModalOpen(!ModalOpen)
         
    } catch (error) {
        if(error.response){
            setMessage('something went wrong')
            setModalOpen(!ModalOpen)
        }
        else{
            console.log(error)
        }
    }
}



    const user= data.map(w=>{
          return(
            <>
              <Accordion.Toggle as={Card.Header} eventKey={w.user}>
                
              <div className='row mt-2'>
                  <div className='col-9'>
                      <h5>{w.name}</h5>
                  </div>
                  <div className='col-3 text-right'>
                      <h6>Status: <b>{w.status}</b></h6>
                  </div>
              </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={w.user}>
              <Card.Body>
                <h6><b>Text Prove:</b></h6>
                <p className='card-text' dangerouslySetInnerHTML={{__html:w.text_prove}}></p>
                {w.screenshot ? <a href={w.screenshot} target="_blank" >Prove</a>: <></>}
                {w.status == 'Pending'? 
                  <p>
                    <span><button className='btn btn-login' onClick={()=> handleAccept(w._id)}>Accept</button> </span>  
                    <button className='btn btn-danger' onClick={() => toggle(w._id)}>Reject</button>
                  </p>:<></>}
              </Card.Body>
              {/* <Card.Footer>
                <p className='card-text' dangerouslySetInnerHTML={{__html:w.text_prove}}></p>
              </Card.Footer> */}
              </Accordion.Collapse>
            </>
          )
    })
  return (
    <>
      <Header/>
        <div className='container after'>
          <div className='row'>
              <div className='col-12 col-md-9 mx-auto '>
                  <div className='card'>
                      <div className='card-header'>
                          <div className='row'>
                              <div className='col-9'>
                                  <h5>Details</h5>
                              </div>
                              <div className='col-3 text-right'>
                                  <h6><span>&#8358;</span>{job.amount}</h6>
                              </div>
                          </div>
                      </div>
                      <div className='card-body'>
                          <p className='card-text' dangerouslySetInnerHTML={{__html:job.description}}></p>
                          <h5><b>Job category</b></h5>
                          <p>{job.category} <span className='ml-4'>{job.subCategory}</span> </p>
                          <h5><b>Required proof that task was finished</b></h5>
                          <p className='card-text' dangerouslySetInnerHTML={{__html:job.proof}}></p>
                          <div>
                              {job.screenshot ? <a href={job.screenshot} target="_blank" >Prove</a>: <></>}
                          </div>
                          {job.reason ? <p>Reason: {job.reason}</p> : <></>}
                      </div>
                  </div>
              </div>
            </div>
            {message}
            <div className='row mt-3'>
                <div className='col-12 col-md-9 mx-auto'>
                  <Accordion className="">
                      <Card>
                        {user}
                      </Card>
                  </Accordion>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <Modal isOpen= {ModalOpen} toggle={toggle}>
                        <ModalHeader  toggle={toggle}></ModalHeader>
                        <ModalBody>           
                                <form onSubmit={(values)=> handleReject(values)}>
                                    <p>Are you sure you want to reject this proof, if yes? Please provide a reason</p>
                                    <textarea rows={3} 
                                    className='form-control' 
                                    value={text}
                                    onChange={(e)=> setText(e.target.value)}
                                    required />
                                    <div className='mt-2 text-right'>
                                        <button className= 'btn btn-danger btn-md'>Yes</button>
                                    </div>
                                </form>
                        </ModalBody>
                        
                    </Modal>
                </div>
            </div>
        </div>
      {/* <Footer/> */}
    </> 
  )
}
