import React, { Component } from 'react'
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { rave } from '../../utils/postData';

export default class Rave extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            name:"",
            email:"",
            phonenumber:"",
            amount: "",
            currency: "NGN",
            success: ""
        }
    }
    

    handleChange = (e)=>{
        this.setState({
            [e.target.name] : e.target.value,
        })
    }


    
    handleSubmit=async(e)=>{
        e.preventDefault()
    }

    render() {
        console.log(this.state)
        const config = {
            // public_key: 'FLWPUBK_TEST-39aa8b1b296016b65cc940693371e9e6-X',
            public_key: 'FLWPUBK_TEST-39aa8b1b296016b65cc940693371e9e6-X',
            tx_ref: Date.now(),
            amount: this.state.amount,
            currency: 'NGN',
            payment_options: 'card,mobilemoney,ussd',
            customer: {
              email: this.state.email,
              phonenumber: this.state.phonenumber,
              name: this.state.name,
            },
            customizations: {
              title: 'Gainworker',
              description: 'Make deposit now',
              logo: 'images/logo.png',
            },
          };
          const fwConfig = {
            ...config,
            text: 'Pay Now',
            callback: (response) => {
               console.log(response);
               const formData ={amount:response.amount, currency:response.currency, status: response.status, transaction_id: response.transaction_id}
               rave(formData).then(res=>{
                    this.setState({success: res.data.message, amount:""})
               })
              closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {},
          };
        return (
            <>
                <section>
                    <div className='container after'>
                    <h4 className='mb-4 pt-3'><b>Make Deposit</b></h4>
                    <div className='row mb-4'>
                        <div className='col-md-6 col-12 mx-auto offset-1 banner text-center'>
                            <h6>
                            <img src={"images/notification.svg"} alt='notification' /> 
                            <span className='ml-1 '>
                                Make sure to have updated your withdrawal details on your dashboard.
                            </span>
                            </h6>
                            {/* <p className='text-center'>{message} <span style={{color:'red'}}>{error}</span> </p> */}
                        </div>
                    </div>
                    <h6 className="text-center text-success">{this.state.success.toLocaleUpperCase()}</h6>
                    <div className="col-lg-6 mx-auto">
                        <div className="card ">
                            
                                <div className='container'>
                                <div className="form-group row">
                                    <div className="col-12 pt-3 ">
                                        <label for="name">
                                            <h6>Name</h6>
                                        </label>                      
                                        <input type="text"  name="name" onChange={this.handleChange} 
                                        className="form-control" required/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label for="email">
                                            <h6>Email</h6>
                                        </label>                          
                                        <input type="text" name="email" onChange={this.handleChange} className="form-control"
                                        required/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12">
                                        <label for="telephone">
                                            <h6>Phone Number</h6>
                                        </label>                          
                                        <input type="text" name="phonenumber" onChange={this.handleChange} className="form-control"
                                        required/>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <div className="col-12 mb-3">
                                            <label for="amount">
                                                <h6>Amount</h6>
                                            </label>  
                                            <input type="text"  name="amount"
                                                placeholder="Enter Amount" 
                                                onChange={this.handleChange}
                                                value={this.state.amount}
                                                className="form-control" required
                                                />
                                    </div>
                                </div>
                                <div className="card-footer"> 
                                    <FlutterWaveButton {...fwConfig}  className="button subscribe btn btn-login btn-block shadow-sm"/>         
                                </div>
                                </div>
                            </div>
                            

                        {/* <form> 
                            <input type="text" name="flutterAmount" className="form-control"/>
                            <button type="button" onClick={this.makePayment}>Pay Now</button>
                        </form> */}
                    </div>
                
            </div>
            <script src="https://checkout.flutterwave.com/v3.js"></script>
        </section>
            </>
        )
    }
}
