import React, {useState, useEffect } from 'react';
import axios from 'axios';

export default function PaymentHistory() {
    const [transaction, setTransaction] = useState([])
    useEffect(() => {
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        axios.get(`${base_url}/payment/history`).then(res=>{
            // console.log(res.data)
            setTransaction([...res.data.trans])
        })
        
    }, [])
    const display = transaction.map(t=>{
        return(
            <div className='col-12'>

                <div key={t._id} className='card mb-2'>
                    <div className='card-body'>
                        <ul className='list-unstyled'>
                            <li>Name: {t.name}</li>
                            <li>Transaction_id: {t.transaction_id}</li>
                            <li>Amount: {t.amount}</li>
                            <li>Currency: {t.currency}</li>
                            <li>Paid at: {t.createdAt}</li>
                            {t.status==='successful'? <li>Status: <span className='bg-success pl-2 pr-2 text-white'> {t.status}</span></li>:
                            <li className='bg-danger p-2 text-white'> {t.status}</li>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    })
    return (
        <div className='container after history'>
            <div className='row'>
                {display ? <h2>No transaction record found</h2> : display}
            </div>
        </div>
    )
}
