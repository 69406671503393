import React, { Component } from 'react';
import ImageUploader from "react-images-upload";
import { CKEditor } from 'ckeditor4-react';
import {Spinner} from 'react-bootstrap';
import styled from 'styled-components';
import Footer from '../Footer';
import Header from '../Header';
import {getId, upload} from '../../utils/postData';
export default class BrowseJobId extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             details: '',
             pictures: [],
             text_prove: '',
             message:"",
             err_message:"",
             user:[],
             loading:false
        }
        this.handleClick = this.handleClick.bind(this)
    }
    componentDidMount(){
        // console.log("this.props.match.params.id")
        let formData = {id: this.props.id}
        getId(formData).then(res=>{
            this.setState({
                details: res.data.message,
                user: res.data.user
            })
       })
    }
    onEditorChange=( evt ) =>{
        this.setState( {
            text_prove: evt.editor.getData()
        });
    }
    onDrop =(picture) =>{
        this.setState({
            pictures: this.state.pictures.concat(picture),
        });
    }
    change =(e) =>{
        this.setState({
            text_prove :e.target.value
        })
    }
    handleClick(name){
        
        this.setState({
            pictures: this.state.pictures.filter(pic => pic.name !== name)
        })
    }
    handleSubmit=(e)=>{
        e.preventDefault()
        this.setState({
            loading: true
        })
        let formData = new FormData();
        var urls=[]
        if(this.state.pictures){
            this.state.pictures.forEach(pic =>{
                formData.append('avatar',pic)
            })
        }
        if(this.state.text_prove){
            formData.append('text_prove',this.state.text_prove)
            // urls.push(this.state.text_prove)
        }
        formData.append('job_id',this.state.details._id)
        // console.log({text:this.state.text_prove})
        upload(formData).then(res=> {
            this.setState({
                pictures: [],
                text_prove: "",
                message: res.data.message,
                err_message:'',
                loading: false
            })
        })
        .catch(err=>{
            if(err.response){
                this.setState({
                    err_message:err.response.data.message,
                    message: '',
                    loading: false
                })
                
            }
        })
    }
    
    render() {
        const name=this.state.pictures.map((pic, index)=>{
                        return(
                            <p key={index}>{pic.name} <span className='ml-3'>
                                <button className='btn btn-xs' onClick={()=>this.handleClick(pic.name)}>x</button> 
                            </span></p>
                        )
                    })
                    
        return (
            <section>
                <Header/>
                    <div className='container-fluid after'>
                        <div className='row'>
                            <div className='col-12 col-md-8 order-last order-md-first'>
                                <div className='card'>
                                    <div className='card-header'>
                                        <div className='row'>
                                            <div className='col-9'>
                                                <h5>Details</h5>
                                            </div>
                                            <div className='col-3 text-right'>
                                                <h6>${this.state.details.amount}</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='card-body'>
                                        <p className='card-text' dangerouslySetInnerHTML={{__html:this.state.details.description}}></p>
                                        <h5><b>Job category</b></h5>
                                        <p>{this.state.details.category} <span className='ml-4'>{this.state.details.subCategory}</span> </p>
                                        <h5><b>Required proof that task was finished</b></h5>
                                        <p className='card-text' dangerouslySetInnerHTML={{__html:this.state.details.proof}}></p>
                                        <div>
                                            <p>Required proof: <a href={this.state.details.screenshot} target={'_blank'}>prove</a> </p>
                                        </div>
                                    </div>
                                    <div className='card-footer text-right'>
                                       <small className='pb-3'>ProjectID: {this.state.details._id}</small>
                                    </div>   
                                </div>
                            </div>
                            <div className="col-12 col-md-4  mb-3">
                                <div className="card">
                                    <div className='card-header'>
                                        About Job owner
                                    </div>
                                    <div className='card-body text-center'>
                                        {this.state.user.avatar ? <img src={this.state.user.avatar} 
                                        alt='profile-picture' 
                                        className='rounded-circle mt-2'
                                        height={100} width={150}/>: <img src={"images/avatar.png"} 
                                        alt='profile-picture' className='rounded-circle mt-2'
                                        height={50} width={50}/>}
                                        
                                        <h6 className='card-text mt-3'><span>Name:</span> {this.state.user.name}</h6>
                                        <h6 className='card-text'><span>Username:</span> {this.state.user.username}</h6>
                                        <h6 className='card-text'><span>Country:</span> {this.state.user.country}</h6>                                            
                                        <h6 className='card-text'><span>State:</span> {this.state.user.state}</h6>                                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-3'>           
                            <div className='col-12 col-md-8'>
                                <div className='card'>
                                    <form className='card-body' onSubmit={this.handleSubmit}>
                                        <h5 className="bg-success text-center">{this.state.message}</h5>
                                        <h5 className="bg-warning text-center">{this.state.err_message}</h5>
                                        <h5>Proof</h5>
                                        <CKEditor
                                            data={this.state.text_prove}
                                            value={this.state.text_prove}
                                            onChange={this.onEditorChange}
                                            id="ck"
                                            
                                            config={ {
                                                toolbar: [ [ 'Bold','Link' ] ],
                                                height: '100px'
                                            } }
                                        />
                                        {/* <textarea rows='3' className="form-control" value={this.state.text_prove} onChange={this.change} required></textarea> */}
                                        <p>upload proof</p>
                                        <ImageUploader
                                            withIcon={true}
                                            buttonText='Choose images'
                                            onChange={this.onDrop}
                                            name='photo'
                                            imgExtension={['.jpg', '.gif', '.png', '.gif']}
                                            maxFileSize={5242880}
                                            withPreview={false}
                                            singleImage={true}
                                        />
                                        <small className='text-center'>{name}</small> 
                                        <div className='mt-3 text-right'>
                                            <button className='btn btn-md btn-login'> 
                                                {this.state.loading ? (
                                                    <Spinner className='border'/>
                                                ): <>Submit Proof</>}
                                            </button> 
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
            </section>
        )
    }
}
