import React, {useState, useRef, useEffect} from 'react';
import {useHistory, useLocation, Link} from "react-router-dom";
// import {useDispatch} from "react-redux";
import { login } from '../../utils/postData';

export default function Login() {
    const { state: { from = "/" } = {} } = useLocation();
    const [state, setState]= useState({
        username: '',
        password:''
    })
    const [user, setUser]= useState('');
    const [message, setMessage]= useState('');
    const [modal, setModal]= useState(false);
    const [loginError, setLoginError]= useState('');
    const history = useHistory();

    useEffect(()=>{
        localStorage.clear('profile')
    }, [])

    const toggleModal=()=>{
        setModal(!modal)
    }
    
    const handleChange = (e)=>{
          setState({
              ...state,
              [e.target.name]: e.target.value
          })
      }
    

      const handleLogin= async e =>{
        e.preventDefault()
        try {
         const result = await login(state)
          if(result.data){
             console.log(result.data);
             localStorage.setItem("profile", JSON.stringify(result.data))
             if(from.pathname){
                history.push(from.pathname)
             }   
             else{
                 history.push('profile')
             }                              
          }

          
     } catch (error) {
         if(error.response){
             console.log(error.response.data.error)
             console.log({loginError: error.response.data.message})
             setLoginError(error.response.data.message)
             // this.setState({loginError: error.response.data.message})
         }
         else{
             console.log(error)
         } 
     }
 }


  return (
    <div className='container'>
        <div className="row">
            <div className="col-12 offset-md-2 col-md-8">
            <div className="text-center sign-up">
                <h4>Welcome back!</h4>
                <h5>Log in to your Gainworkers account</h5>
                <h6 className="bg-danger">{message}</h6>
            </div>
                <form   className="mb-4 login">
                    <div  className='container'>
                        <div className="form-group row pt-3 pb-2">
                            <div className="col-12 col-md-6 big text-right lft">
                                <button className='btn btn-lg facebook btn-primary ml-3'>Facebook</button> 
                            </div>
                            <div className="col-12 small">
                                <button className='btn btn-lg facebook btn-primary ml-3'>Facebook</button> 
                            </div>
                            <div className="col-12 col-md-6">
                                <button  className='btn btn-lg google btn-login'>Google</button>
                            </div>
                        </div>
                        <div className="form-group ">
                            <div className='text-center'>
                                <small className='bg-danger text-white'>{loginError}</small> <br/>
                            </div>
                            <label className='ml-3'>Username</label>
                            <div className="col-12 ">                          
                                <input type="text" name="username"  
                                className="form-control" onChange={handleChange}
                                placeholder="username"/> 
                            </div>
                        </div>
                        <div className="form-group ">
                            <label className='ml-3'>Password <span> <small><Link> Forgot?</Link></small> </span></label>
                            <div className="col-12 ">                          
                                <input type="text" name="password" 
                                className="form-control" onChange={handleChange}
                                placeholder="password" required /> 
                            </div>
                        </div>
                        
                        
                        <div className="col-12 pb-4 text-right">
                            <button className="btn btn-md btn-login w-100" onClick={handleLogin}>Login</button>
                        </div>
                    </div>
                        <div className='text-center'>
                            <small>Not a member? <Link to='signup' onClick={toggleModal}>Sign up now</Link></small>
                        </div>
                </form>
                    
            </div>
        </div>
    </div>
  )
}
