import React from 'react'
import Jumbotron from './Pages/Jumbotron'
import SectionOne from './Pages/SectionOne'
import SectionThree from './Pages/SectionThree'
import SectionTwo from './Pages/SectionTwo'
import SectionFour from './Pages/SectionFour'
import SectionFive from './Pages/SectionFive'
import AboutUs from './Pages/AboutUs'
import Count from './Pages/Count'
import GetStarted from './Pages/GetStarted'
import Subscribe from './Pages/Subscribe'
import Advert from './Pages/Advert'

export default function Home() {
    return (
        <div>
            <Jumbotron/>
            <SectionOne/>
            <AboutUs/>
            <Count/>
            <SectionThree/>
            <GetStarted/>
            <SectionTwo/>
            
            <SectionFour/>
            <SectionFive/>
            <Subscribe/>
            <Advert/>
        </div>
    )
}
