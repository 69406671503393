import React, {useEffect} from 'react'
import { Link, useLocation} from 'react-router-dom'

export default function AboutUs() {
    // const location = useLocation()
    
    return (
        <div className='container' id="myid">
            <div className='row'>
                <div className='col-12 offset-md-2 col-md-8 text-center'>
                    <h4>ABout US</h4>
                    <p className='p'>
                        GainWorkers is an online independent platform for freelancers and business owners. We run a service that allows people  globally to earn real money executing simple & easy digital tasks, post campaigns, advertise, offer services, and so much more. We provide a home user base services and a stress free work from home tasks.
                        
                    </p>
                    
                </div>
            </div>

        </div>
    )
}
