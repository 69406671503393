import React, {useState, useRef, useEffect} from 'react';
import { Nav, Navbar, NavItem, DropdownButton, Dropdown, Carousel} from 'react-bootstrap';
import {NavLink, useHistory, Link, useLocation} from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
// import {useDispatch} from "react-redux";
import styled from 'styled-components';
import { login } from '../utils/postData';


export default function Header() {
    const  Styles= styled.div`
    .nav{
        background-color: white;
        height:70px
    }
    #dropdown-basic-button{
        // color: white !important;
    }
    .nav-link{
        color: black !important
    }
    .btn-login{
        background-color: #26534c;
        color: white;
        border-radius: 0px;
    }
    @media (max-width: 768px) {
    #responsive-navbar-nav{
    background-color: rgba(0, 0, 0, 8);
    //   height: 100vh;
    margin:0px !important;
    width:100% !important;
    transition: 0.4s;
    z-index: 1000000;
    }
    img{
        width: auto
    }
    Nav .nav-link{
    margin-top: 20px !important;
    width: 100% !important;
    color: white !important;
    text-align: left;
    
    }
    #dropdown-basic-button{
    font-size: 18px;
    width: 100% !important;
    background-color: black !important;
    padding-top: 0px;
    color: white !important;
    text-align: left;
    }
    .btn-success{
    /* width: 98% !important; */
    height: 50px;
    width: 88px !important;
    margin-top: 0px !important;
    }

    .mx-auto{
    margin-left:0px !important;
    }
    .nav-link{
    margin:0px !important;
    padding-bottom: 5px;
    padding-left: 9px !important;
    
    }
    Nav .nav-link:hover{
    border-bottom:none !important;
    /* background-color: rgb(22, 22, 22) !important; */
    }
    .row-header1{
    /* background-image: url("/images/banner_edit.jpg"); */
    background-position: center;
    background-size: cover;
    height: 250px;
    }

    .navbar-collapse {
        /* position: absolute; */
        top: 54px;
        left: 0;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        width: 100%;
    }
    .navbar-collapse.collapsing {
        height: auto;
        -webkit-transition: left 0.3s ease;
        -o-transition: left 0.3s ease;
        -moz-transition: left 0.3s ease;
        transition: left 0.3s ease;
        left: -100%;
    }
    .navbar-collapse.show {
        left: 0;
        -webkit-transition: left 0.3s ease-in;
        -o-transition: left 0.3s ease-in;
        -moz-transition: left 0.3s ease-in;
        transition: left 0.3s ease-in;
    }
    }
    
`
    const [state, setState]= useState({
        username: '',
        password:''
    })
    const [user, setUser]= useState('');
    const [message, setMessage]= useState('');
    const [modal, setModal]= useState(false);
    const [loginError, setLoginError]= useState('');
    const history = useHistory();
    const location = useLocation();
    // const dispatch = useDispatch();
    
    // const user = false
    // const location = useLocation();
    useEffect(()=>{
        setInterval(() => {
            setUser(JSON.parse(localStorage.getItem('profile')))
            }, 500)
    }, []);
       
      const toggleModal=()=>{
        //   if(location.pathname === '/home') setModal(!modal)
        //   else history.push('login')
        setModal(!modal)
      }
      
      const handleChange = (e)=>{
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
        }
    const handleLogin= async e =>{
           e.preventDefault();
           try {
            const result = await login(state)
             if(result.data){
                console.log(result.data);
                localStorage.setItem("profile", JSON.stringify(result.data))
                setModal(!modal)
                    history.push('profile')
             }

             
        } catch (error) {
            if(error.response){
                setLoginError(error.response.data.message.toLocaleUpperCase())
            }
            else{
                console.log(error)
            } 
        }
    }
    const logout = (e)=>{
        // dispatch({type: 'LOGOUT'})
        history.push("/home")
        localStorage.clear("profile")
        
        // window.location.reload();   
    }
       

    const handleAbout = () => {
        if(location.pathname ==='/home'){
            document.getElementById("myid").scrollIntoView({ behavior: "smooth" });
        }
        else{
            return null
        }
      };
    const handleGettingStarted = () => {
        if(location.pathname ==='/home'){
            document.getElementById("getting-started").scrollIntoView({ behavior: "smooth" });
        }
        else{
            history.push('/home')
        }
      };
    
    return (
        <>
        {!user ?
            <Navbar collapseOnSelect expand="lg"  className='nav fixed-top' variant='dark'>
                <div className="container-fluid">
                    <Navbar.Brand  href="/home"><img src={'images/G.png'} height="35" width="150" alt='GainWorkers' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" id='collapse-fontawesome'/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">
                            <NavItem>
                                <NavLink className="nav-link ml-2 active" to='/home'> Home</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link ml-2" to='/home'   onClick={handleAbout}> About Us</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link ml-2" onClick={handleGettingStarted}  to='#'> Get Started</NavLink>
                            </NavItem>
                            <DropdownButton id="dropdown-basic-button" 
                                    title="Market" variant=' #f27B13'
                                    className='dropdown'>
                                    <Dropdown.Item href="/wallet">About us</Dropdown.Item>
                                    <Dropdown.Item href="/wallet">Subscribe</Dropdown.Item>
                                    <Dropdown.Item href="/wallet">Contact us</Dropdown.Item>
                            </DropdownButton>
                            {/* <NavItem>
                                <NavLink className="nav-link ml-2"  to='#'> Referral</NavLink>
                            </NavItem> */}
                            <NavItem>
                                <NavLink className="nav-link ml-2"  to='#'> Contact</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link ml-2"  to='#'> FAQ</NavLink>
                            </NavItem>
                        </Nav>
                        <Nav className='align-self-center text-right'>
                            <NavItem>
                                <NavLink className="nav-link ml-2 "  to='#' onClick={toggleModal}> 
                                <button className='btn btn-md btn-login'><span><i className='fa fa-user'></i></span> Log In | Register</button>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>:
            <Navbar collapseOnSelect expand="lg"  className='nav fixed-top' variant='dark'>
                <div className="container-fluid">
                    <Navbar.Brand  href="/home"><img src={'images/G.png'} height="35" width="150" alt='GainWorkers' /></Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" id='collapse-fontawesome'/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mx-auto">
                            
                        </Nav>
                        <Nav className='align-self-center text-right'>
                            <NavItem>
                                <NavLink className="nav-link ml-2 mt-2 active" to='/home' > Home</NavLink>
                            </NavItem>
                            <NavItem>
                            <DropdownButton id="dropdown-basic-button" 
                                    title="Jobs" variant=' #f27B13'
                                    className='dropdown nav-link'>
                                    <Dropdown.Item href="/job">Available Jobs</Dropdown.Item>
                                    <Dropdown.Item href="/myjob">My Jobs</Dropdown.Item>
                                    <Dropdown.Item href="#">Task Done</Dropdown.Item>
                            </DropdownButton>
                                
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link ml-2 mt-2" to='#' > Wallet</NavLink>
                            </NavItem>
                            <DropdownButton id="dropdown-basic-button" 
                                    title="Market" variant=' #f27B13'
                                    className='dropdown nav-link'>
                                    <Dropdown.Item href="/wallet">About us</Dropdown.Item>
                                    <Dropdown.Item href="/wallet">Subscribe</Dropdown.Item>
                                    <Dropdown.Item href="/wallet">Contact us</Dropdown.Item>
                            </DropdownButton>
                            <NavItem>
                                <NavLink className="nav-link ml-2 mt-2"  to='/browse'> Contact</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link ml-2 mt-2"  to='/profile'> 
                                My Account
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className="nav-link ml-2 "  to='/postJob'> 
                                    <button className='btn btn-md btn-login nav-link ml-2 mr-2'>Post New Job</button>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>}
            
             <div className="row">
                <div className="col-12">
                    <Modal isOpen= {modal} toggle={toggleModal}>
                        <ModalHeader  toggle={toggleModal}>Login</ModalHeader>
                        <ModalBody>
                            <div className="text-center">
                                <h4>Welcome back!</h4>
                                <p>Log in to your Gainworkers account</p>
                                <h6 className="text-danger">{message}</h6>
                            </div>
                                <form   className="mb-4 login">
                                    <div  className='container'>
                                        <div className="form-group row pt-2 pb-3 ">
                                            <div className="col-12 col-md-6 big text-right lft">
                                                <button className='btn btn-lg facebook btn-primary ml-3'>Facebook</button> 
                                            </div>
                                            <div className="col-12 small">
                                                <button className='btn btn-lg facebook btn-primary ml-3'>Facebook</button> 
                                            </div>
                                            <div className="col-12 col-md-6">
                                                <button  className='btn btn-lg google btn-login'>Google</button>
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <div className='text-center'>
                                                <small className="text-danger">{loginError}</small> <br/>
                                            </div>
                                            <label className='ml-3'>Username</label>
                                            <div className="col-12 ">                          
                                                <input type="text" name="username"  
                                                className="form-control" onChange={handleChange}
                                                placeholder="username"/> 
                                            </div>
                                        </div>
                                        <div className="form-group ">
                                            <label className='ml-3'>Password <span> <small><Link> Forgot?</Link></small> </span></label>
                                            <div className="col-12 ">                          
                                                <input type="password" name="password" 
                                                className="form-control" onChange={handleChange}
                                                placeholder="password "/> 
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="col-12 pb-4 text-right">
                                            <button className="btn btn-md btn-login w-100" onClick={handleLogin}>Login</button>
                                        </div>
                                    </div>
                                        <div className='text-center'>
                                            <small>Not a member? <Link to='signup' onClick={toggleModal}>Sign up now</Link></small>
                                        </div>
                                </form>
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </>
    )
}
