import React, {useEffect} from 'react';
import {Switch, Route, Redirect, Link} from 'react-router-dom';
import axios from 'axios'
import Header from '../Header'
import Footer from '../Footer'
import Home from '../Homepage/Index'
import Signup from '../Forms/Signup'
import Withdrawal from '../Forms/Withdrawal'
import Profile from '../Profile/Profile'
import PostJobs from '../postJob/PostJobs';
import PaymentHistory from '../Payment/PaymentHistory'
import Upgrade from '../Profile/pages/Upgrade'
import Rave from '../Payment/Rave'
import BrowseJob from '../BrowseJob/BrowseJob'
import BrowseJobId from '../BrowseJob/BrowseJobId';
import MyJob from '../Profile/pages/MyJob'
import AllJob from '../postJob/AllJob'
import CheckJobProof from '../postJob/CheckJobProof'
import Login from '../Forms/Login';

export default function MainComponent() {
    // useEffect(()=>{
    //     const base_url = 'https://immense-bayou-01640.herokuapp.com';
    //     axios.get(`${base_url}/job/iscomplete`).then(res=> console.log(res.data))
    // }, [])
    return (
        <div>
            
            <Switch>
                <Route exact path={'/home'}>
                   <Header /> 
                    <Home/>
                    <Footer/>
                </Route>
                <Route path='/signup'>
                        <Signup/>
                </Route>
                <Route path='/login'>
                    <Login/>
                </Route>
                <Route path='/profile'>
                    <Header/>
                    <Profile/>
                    {/* <Footer/> */}
                </Route>
                <Route path='/postJob'>
                    <Header/>
                    <PostJobs/>
                    <Footer/>
                </Route>
                <Route path='/rave'>
                    <Header />
                    <Rave/>
                    <Footer/>
                </Route>
                <Route path='/transaction/history'>
                    <Header />
                    <PaymentHistory/>
                    <Footer/>
                </Route>
                <Route path='/account/upgrade'>
                    <Header />
                    <Upgrade/>
                    <Footer/>
                </Route>
                {/* Post job */}
                <Route path='/job'>
                    <Header />
                    <BrowseJob/>
                    <Footer/>
                </Route>
                {/* <Route path='/postedTask'>
                    <Header/>
                    <MyJob/>
                    <Footer/>
                </Route> */}
                <Route path='/myjob'>
                    <Header/>
                    <AllJob/>
                    <Footer/>
                </Route>
                <Route path='/withdraw'>
                    <Header/>
                    <Withdrawal />
                    <Footer/>
                </Route>
                
                <Route path='/browse/:id' render={(props) => 
                    {
                        if (props.match.params.id) return (
                        <BrowseJobId id={props.match.params.id}/>)
                    } }/>
                
                <Route path='/postedTask/proof/:id' render={(props) => 
                    {
                        if (props.match.params.id) return (
                        <CheckJobProof id={props.match.params.id}/>)
                    } }/>
                <Route path='/ref/:id' render={(props) => 
                    {
                        if (props.match.params.id) return (
                        <Signup referral={props.match.params.id}/>)
                        else return(<Redirect to="/signup"/>) 
                    } }/>
                <Route path='*'/>
                    <Header />
                    <Home/>
                    <Footer/>
                <Route/>
            </Switch>
            
        </div>
    )
}