import React, {useState, useEffect} from 'react';
import axios from 'axios'
import { Link } from 'react-router-dom';
import styled from 'styled-components';
export default function BrowseJob() {
    const [data, setData] = useState([])
    useEffect(()=>{
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        axios.get(`${base_url}/job/available`).then(res=>{
            setData(res.data.message)
            })
    }, [])
    console.log(data)
    const Styles = styled.div`
            a{
                color: black
            }
            p{
                font-size: 14px
            }
            a:hover{
                text-decoration: none;
            }
            a:hover .card{
                background: rgb(250, 248, 248) !important;
            }
            a:hover .card button{
                display: inline !important;
            }
            .search h6{
                font-size: 16px;
            }
            
            @media (max-width: 768px) {
                .btn-success{
                    font-size:12px;
                }
            }
        `
    const accepted = data.filter((ne)=> ne.status=="Accepted")
    const accept = accepted.filter((ne)=> ne.count !==ne.workers)
    // console.log(accept)
    const JobsSponsoredBold  = accept.map(job=>{
        if(job.sponsored && job.bold){

            return(
                <div className='col-12' key={job._id}>
                    <Link to={`browse/${job._id}`}>           
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <img src='fjsk' alt="profile"/>
                                        
                                    </div>
                                    <div className='col-3'>
                                        <h6 className=''>{job.title} </h6>
                                    </div>
                                    <div className='col-2'>
                                        <button className='btn btn-md btn-danger'>Sponsored</button>
                                    </div>
                                    <div className='col-1 align-self-center'>
                                            <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>
                                            {job.count}/{job.workers} </h6>
                                    </div>
                                    <div className='col-1 align-self-center'>
                                            <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>{`${job.amount}.00`} </h6>
                                    </div>
                                    <div className='col-3 align-self-center text-right'>
                                        <button className="btn btn-sm btn-login" >View Task Details</button>
                                    </div>
                                </div>
                                
                                {/* <span><small>{job.category} </small></span>
                                
                                <span className='ml-3'> <small>{job.subCategory}</small></span> */}
                            </div>
                        </div>
                    </Link>
                </div>
            )
        }
        else{
            return <></>
        }
    })
    const JobsSponsored  = accept.map(job=>{
        if(job.sponsored){
            return(
                <div className='col-12' key={job._id}>  
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-1'>
                                    <img src={job.avatar} 
                                    alt='profile-picture' 
                                    className='rounded mt-2'
                                    height={30} width={30}/>
                                    </div>
                                    <div className='col-3'>
                                        <h6 className='mt-2'>{job.title} </h6>
                                    </div>
                                    <div className='col-2'>
                                        <button className='btn btn-md btn-danger'>Sponsored</button>
                                    </div>
                                    <div className='col-1 align-self-center'>
                                            <h6><span className='ml-3'><i className='fa fa-user'></i></span>
                                            {job.count}/{job.workers} </h6>
                                    </div>
                                    <div className='col-2 align-self-center text-right'>
                                            <h6><span className='ml-3'><i className='fa fa-user'></i></span>{`${job.amount}.00`} </h6>
                                    </div>
                                    <div className='col-3 align-self-center text-right'>
                                        <Link to={`browse/${job._id}`}>
                                        <button className="btn btn-sm btn-login" 
                                        style={{height: "40px", borderRadius: "14px"}} >View Task Details</button>
                                        </Link>
                                    </div>
                                </div>
                                
                                {/* <span><small>{job.category} </small></span>
                                
                                <span className='ml-3'> <small>{job.subCategory}</small></span> */}
                            </div>
                        </div>
                </div>
            )
        }
        else{
            return <></>
        }
    })
    const JobsBold  = accept.map(job=>{
        if(job.bold){
            return(
                <div className='col-12' key={job._id}>
                    <Link to={`browse/${job._id}`}>           
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                        <img src='fjsk' alt="profile"/>
                                    </div>
                                    <div className='col-5'>
                                        <h6 className=''>{job.title} </h6>
                                    </div>
                                    <div className='col-1 align-self-center'>
                                            <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>
                                            {job.count}/{job.workers} </h6>
                                    </div>
                                    <div className='col-1 align-self-center'>
                                            <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>{`${job.amount}.00`} </h6>
                                    </div>
                                    <div className='col-3 align-self-center text-right'>
                                        <button className="btn btn-sm btn-login" >View Task Details</button>
                                    </div>
                                </div>
                                
                                {/* <span><small>{job.category} </small></span>
                                
                                <span className='ml-3'> <small>{job.subCategory}</small></span> */}
                            </div>
                        </div>
                    </Link>
                </div>
            )
        }
        else{
            return <></>
        }
    })
    const Jobs  = accept.map(job=>{
            return(
                <div className='col-12' key={job._id}>
                    <Link to={`browse/${job._id}`}>           
                        <div className='card'>
                            <div className='card-body'>
                                <div className='row'>
                                    <div className='col-2'>
                                    {job.avatar ? <img src={job.avatar} 
                                        alt='profile-picture' 
                                        className='rounded-circle mt-2'
                                        height={44} width={70}/>: <img src={"images/avatar.png"} 
                                        alt='profile-picture' className='rounded-circle mt-2'
                                        height={44} width={70}/>}
                                    </div>
                                    <div className='col-3 text-center align-self-center'>
                                        <h6 className=''>{job.title} <button className='btn ml-2 btn-md btn-danger'>Sponsored</button></h6>
                                    </div>
                                    <div className='col-3 text-center align-self-center'>
                                            <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>
                                            {job.count}/{job.workers} </h6>
                                    </div>
                                    <div className='col-2 align-self-center'>
                                            <h6><span className='ml-3'><i className='fa fa-user mr-2'></i></span>{`${job.amount}.00`} </h6>
                                    </div>
                                    <div className='col-2 align-self-center text-right'>
                                        <button className="btn btn-sm btn-login" >View Task Details</button>
                                    </div>
                                </div>
                                
                                {/* <span><small>{job.category} </small></span>
                                
                                <span className='ml-3'> <small>{job.subCategory}</small></span> */}
                            </div>
                        </div>
                    </Link>
                </div>
            )
        
    })
    // console.log(accept)
    return (
        <Styles>
            <section className='container after '>
                <h5 className='text-center mb-5' style={{fontWeight: 'bold'}}>Available Tasks</h5>
                <div className='row mb-4'>
                    <div className='col-12'>
                        <div className='card search'>
                            <div className='row m-3 '>
                                <div className='col-2 align-self-center'>
                                    <h6><b>Categories: </b></h6>
                                </div>
                                <div className='col-3'>
                                    <select name="category" className="form-control">
                                            <option>All Categories</option>
                                            <option >Youtube</option>
                                            <option>Instagram</option>
                                            <option>Twitter</option>
                                    </select> 
                                </div>
                                <div className='offset-2 col-1 align-self-center'>
                                    <h6><b>Sort By:</b></h6>
                                </div>
                                <div className='col-3'>
                                    <select name="category" className="form-control">
                                            <option>All Categories</option>
                                            <option >Youtube</option>
                                            <option>Instagram</option>
                                            <option>Twitter</option>
                                    </select> 
                                </div>
                                <div className='col-1'>
                                    <button className='btn btn-md btn-login'>Search</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col-12'>
                        <div className='card grey'>
                            <div className='card-body pt-2 mt-0'>
                                <div className='row '>
                                    <div className='col-md-2'>
                                        <h6><b>Employer</b></h6>
                                    </div>
                                    <div className='col-md-3 text-center'>
                                        <h6><b>Task Title</b></h6>
                                    </div>
                                    <div className='col-md-3 text-center'>
                                        <h6><b>Task Progress</b></h6>
                                    </div>
                                    <div className='col-md-2 '>
                                        <h6><b>Earn per task</b></h6>
                                    </div>
                                    <div className='col-md-2 text-center'>
                                        <h6><b>Action</b></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* {JobsSponsoredBold}
                    {JobsSponsored}
                    {JobsBold} */}
                    {Jobs}
                </div>
            </section>
        </Styles>
    )
}
