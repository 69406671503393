import React, { useState, useMemo } from 'react'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import { Nav, Navbar, NavItem, DropdownButton, Dropdown, Carousel} from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
export default function Jumbotron() {
     const Styles = styled.div`
     .jumbotron{
        background-color: #fff !important;
        height:600px
     }
     h3{
        font-family: Bitter;
        font-size: 31px;
        // width: 93%;
        font-weight: bold;
        line-height: 1.3;
     }
     .row{
         padding-top:100px;
     }
        .start{
            // height: 60px;
            border-radius: 0px;
            background-color: #26534c;
            color: #fff;
            font-family: Bitter
        } 
        .link{
            color: #26534c;
        }
        @media (max-width: 768px) {
  
            .jumbotron img{
                // background-color: #fff !important;
                height:auto !important;
                width: 300px !important;
             }
            .jumbotron{
                // background-color: #fff !important;
                margin-bottom: 170px !important;
             }
          }
        
    `
 

  
    return (
        <Styles>
        
            <div className='jumbotron mt-0' id='home' >
            <div className='container'>
                <div className='row'>
                    <div className='col-12  col-md-5 intro'>
                        <h3>
                        <b>Earn xtra money while working from home and staying stress-free.</b>
                        </h3>
                        <p className='mt-3'>
                           Join Gainworkers and earn extra money by completing simple, 
                           easy accessible tasks from any where at any time. 
                           The platform provides easy-to-use solutions for business owners who want to scale 
                           up and maximize their sales.
                        </p>
                        <Link to='/signup'>
                            <button className='start btn btn-lg'>
                            Start Earning
                            </button> 
                        </Link> 
                        <Link className='link ml-5'> <span> <i class="fas fa-caret-right"></i> </span> Watch How    </Link>
                    </div>
                    <div className='col-12 offset-md-1 col-md-6 circle'>
                        <img src={'images/banner-image.svg'} alt='banner-image' height='500'/>
                    </div>
                </div>
                
               
            </div>
            </div>
        </Styles>
    )
}
