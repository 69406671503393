import React from 'react'
import styled from 'styled-components';

export default function SectionThree() {

    const Styles = styled.div`
    .container{
        background-color: white !important;
        border-radius: 12px !important
    }
    h4{
        color: #102226;
    }
    .category i{
        justify-content: !important;
        text-align: center !important;
    }
    
    img{
        height: 25px;
        margin-bottom: 15px;
    }
    .pa{
        color: #474747;
    }
    .first{
        border: 1px solid grey;

    }
    .second{
        border: 1px solid grey;
    }
    button{
        background-color: #26534c;
    }

    `
    return (
        <Styles>
        
            <div className='container'>
                {/* <div className='row mt-3 mb-3'>
                    <div className='col-12 '>
                        <p className=' mb-3 category'>
                            Advertisement Space – 720 x 90
                        </p>
                    </div>
                </div> */}
                
                <h4 className=' category mb-4 text-center'>
                Task Categories
                </h4>
                
                <div className='row first  text-center'>
                    <div className='col-md-3 col-6  pt-3 mb-3 category'>
                        <img src={'images/signup.svg'} alt='signup'/>
                        <p>Sign Up (0) </p>
                        <p className='pa'>Register To Any Website</p>
                    </div>
                    <div className='col-md-3 col-6 pt-3 mb-3 category'>
                        <img src={'images/download.svg'} alt='Downloads'/>
                        <p>Downloads (0) </p>
                        <p className='pa'>Request Your Content To Be Downloaded </p>
                    </div>
                    <div className='col-md-3 col-6 pt-3 mb-3 category'>
                        <img src={'images/social-media.svg'} alt='social-media'/>
                        <p>Social Media (0)</p>
                        <p>Get Followers, Likes, Comments On Social Media Platforms </p>
                    </div>
                    
                    <div className='col-md-3 col-6 pt-3 mb-3 category'>
                        <img src={'images/reffer.svg'} alt='referral'/>
                        <p>Referrals (0)</p>
                        <p className='pa'>Want Members To Register With Your Unique Links</p>
                    </div>
                </div>
                <div className='row second mb-4 text-center'>
                    <div className='col-md-3 col-6 pt-3 mb-3 category'>
                        <img src={'images/vote.svg'} alt='vote'/>
                        <p>Voting (0)</p>
                        <p>Get votes For Anything</p>
                    </div>
                    <div className='col-md-3 col-6 pt-3 mb-3 category'>
                        <img src={'images/review.svg'} alt='review'/>
                        <p><>Rating / Review (0)</> </p>
                        <p>Get Comments, Review And Feedback For Anything</p>
                    </div>
                    <div className='col-md-3 col-6 pt-3 mb-3 category'>
                        <img src={'images/article.svg'} alt='article'/>
                        <p><>Article (0)</></p>
                        <p className='pa'>Request An Article From Members</p>
                    </div>
                    <div className='col-md-3 col-6 pt-3 mb-3 category'>
                        <img src={'images/targer.svg'} alt='targer'/> 
                        <p><>SEO Ranking / Search</></p>
                        <p>Improve your SEO by having your keywords searched and links clicked.</p>
                    </div>
                    <div className=' col-12 text-center pb-3'>
                      <button className='text-white btn btn-md'>View All Categories</button>
                    </div>
                </div>
            </div>
        </Styles>
    )
}
