import React, { useState, useEffect } from 'react';
//import {getAll} from '../../../utils/postData';
import axios from "axios";

import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

const Styles = styled.div`
    .card-footer{
        height: 23px;
        padding-top: 0px;
    }
    a{
        text-decoration:none;
    }
    h3{
        font-weight: bold;
        font-size: 30px
    }
    p{
        font-family: Manrope;
    }
    `

export default function Dash() {
    // Job
    const [data, setData]= useState([]);
    const [allJob, setAllJob]= useState([]);
    const [pendingFund, setPendingFund]= useState([]);
    const [accept, setAccept]= useState([]);
    const [pend, setPend]= useState([]);
    const [reject, setReject]= useState([]);
    
    const History = useHistory()
    // User
    const [allUser, setAllUser]= useState([]);
    useEffect(() => {
        // axios.get('/admin/job')
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        async function myAPI(){
            try {
                const first = await axios.get(`${base_url}/admin/user/all`)
                setAllUser(first.data.data);
                const second = await axios.get(`${base_url}/admin/job`)
                setAllJob(second.data.data)
                const third = await axios.get(`${base_url}/admin/withdraw/request`)
                setPendingFund(third.data)
            } catch (error) {
                switch (error.response.status){
                    case 403:
                        History.push('/403')
                }
            }
        }
        myAPI()
        
        
    }, [])
    const newJob = allJob.filter((ne)=> ne.status==="Pending")
    return (
        <div className='wrapper'>
            <Styles>
            <div className="container-fluid">
                        <h5>Dashboard</h5><hr/>
                        <div className="row">
                            <div className=" col-6 mt-3 col-md-3 ">
                                <div className="card  bg-info">
                                    <div className="card-body pt-0  pb-0">
                                        <div className='row'>
                                            <div className='col-6'>
                                                <h3 className='text-white'>{newJob.length}</h3>
                                                <p className='down text-white'>New Job</p>
                                            </div>
                                            <div className='col-6'></div>
                                        </div>
                                    </div>
                                    <Link to="/admin/job/new">
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <div className="card bg-success">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{accept.length}</h3>
                                                <p className='down text-white'>Accepted Job</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to="/accepted">
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <div className="card bg-danger">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{reject.length}</h3>
                                                <p className='down text-white'>Rejected Job</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to="/reject">
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <div className="card bg-secondary">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-6'>
                                                <h3 className='text-white'>{allJob.length}</h3>
                                                <p className='down text-white'>Total Job</p>
                                            </div>
                                            <div className='col-6'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/job/total'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-6 mt-3">
                                <div className="card bg-warning">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-6'>
                                                <h3 className='text-white'>{allUser.length}</h3>
                                                <p className='down text-white'>All User</p>
                                            </div>
                                            <div className='col-6'></div>
                                        </div>
                                    </div>
                                    <Link to='/user/all'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <div className="card bg-primary">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{data.length}</h3>
                                                <p className='down text-white'>Delete User</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to='#'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className=" col-6 mt-3 col-md-3 ">
                                <div className="card bg-info">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{pendingFund.length}</h3>
                                                <p className='down text-white'>Payment Request</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to='/admin/pendingfund'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 mt-3">
                                <div className="card bg-success">
                                    <div className="card-body pt-0 pb-0">
                                        <div className='row'>
                                            <div className='col-8'>
                                                <h3 className='text-white'>{data.length}</h3>
                                                <p className='down text-white'>Payment History</p>
                                            </div>
                                            <div className='col-4'></div>
                                        </div>
                                    </div>
                                    <Link to='#'>
                                        <div className="card-footer text-center">
                                            <small className='text-white'>
                                                More Info
                                            </small>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
            </Styles>
        </div>
    )
}
