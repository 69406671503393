import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
  
        button{
          border: solid 1px #000 !important;
          height: 50px !important;
          font-size: 19px;
          background-color: #26534c;
        }
        h3{
            font-family: Bitter;
            font-size: 30px;
            margin-top: 21px;
            font-weight: bold;
        }
        .jumbotron{
            height: 300px !important;
            border-radius: 0px
        }
        @media (max-width: 768px) {
  
            button{
                border: solid 1px #000 !important;
                height: auto !important;
                font-size: auto;
                background-color: #26534c;
              }
            h3{
                font-size: 27px;
                margin-top: 30px !important;
            }
            .jumbotron{
                height: 250px !important;
                border-radius: 0px
            }
        }
        `

export default function Subscribe() {
    return (
        <Styles>
            <div className='jumbotron'>
                <div className='container text-center'>
                    <h3>Subscribe To Our Channel for updates</h3>
                    <button className='btn btn-md mt-3 text-white'>
                    Subscribe To GainWorkers
                    </button>
                </div>
            </div>
        </Styles>
    )
}
