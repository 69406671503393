import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link} from 'react-router-dom'
import { updatePaymentRequest } from '../../../utils/postData';


export default function PendingWithdrawal() {
    const [pendingFund, setPendingFund]= useState([]);
    const [message, setMessage]= useState('');
    useEffect(() => {
        // axios.get('/admin/job')
        const base_url = 'https://immense-bayou-01640.herokuapp.com';
        async function myAPI(){
            try {
                const third = await axios.get(`${base_url}/admin/withdraw/request`)
                setPendingFund(third.data)
            } catch (error) {
                console.log(error)
            }
        }
        myAPI()
        
        
    }, [])

    const handleClick = (id)=>{
        let formData = {id}
        updatePaymentRequest(formData).then(res=> setMessage(res.data.message)).catch(err=> {throw new Error(err)})
        window.location.reload()
    }
    
    const tBody=  pendingFund.map((req)=>{
        return(
            
                <tr key={req._id}> 
                    <td>{req.user}</td>
                    <td>{req.account_name}</td>
                    <td>{req.account_number}</td>
                    <td>{req.amount}</td>
                    <td>{req.bank}</td>
                    <td>{req.taskBalance}</td>
                    <td>
                        <button className="btn btn-md btn-login" onClick={()=> handleClick(req._id)} >change status</button> 
                    </td>
                </tr>
            
        )
    })
    return (
        <div className='container-fluid'>
            <div className='breadcrumb'>
                <small className='breadcrumb-item'> <Link to='/dashboard'>Dashboard</Link> </small>
                <small className='breadcrumb-item'>Pending Request</small>
            </div>
            <div className='row mt-5'>
                <h6 className='text-center'>{message}</h6>
                <div className='col-lg-6 mx-auto'>
                    <table className="table table-responsive">
                        <thead>
                            <tr>
                                <th>User</th>
                                <th>Account Name</th>
                                <th>Account Number</th>
                                <th>Amount</th>
                                <th>Bank</th>
                                <th>Task Balance</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {tBody}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}
